import { screenSizes } from 'config'
import styled from 'styled-components'

export const SCCol = styled.div`
  display: flex;
  flex-direction: column;
  padding-right: 20px;

  @media (max-width: ${screenSizes.mobile}) {
    flex: 1;
    width: 100% !important;
  }
`
