import styled from 'styled-components'

export const SCCard = styled.div`
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 15px;
  box-shadow: 0 10px 30px 0 rgba(17, 38, 146, 0.05);
  display: flex;
  flex-direction: column;
  width: 100%;
`
