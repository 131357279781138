// Styled Components
import styled from 'styled-components'
// Global Config
import { colors, fonts } from 'config'
// Input Default
import { SCInputDefault } from '../styles-default'

export const SCInputDate = styled.div`
  input {
    ${SCInputDefault}
  }

  .react-datepicker {
    font-family: '${fonts.primary}', sans-serif;
  }

  .react-datepickerday--selected {
    background-color: ${colors.secondary};
  }

  .react-datepickerday--today {
    color: ${colors.secondary};
  }

  .react-datepickerheader {
    background-color: #f4f6fe;
  }

  .react-datepickermonth {
    padding-right: 10px;
  }
`
