// Styled Components
import styled from 'styled-components'
// Local TypeScript Types
import { IFormGroup } from './types'

export const SCFormGroup = styled.div<IFormGroup>`
  margin-bottom: ${(props) =>
    props.marginBottom !== undefined ? String(props.marginBottom) : '15'}px;
  width: 100%;
`
