// Local Styled Components
import { SCCardBody } from './styles'
// Local TypeScript Types
import { ICardBody } from './types'

const CardBody: React.FC<ICardBody> = ({ children, minWidth }: ICardBody) => {
  return <SCCardBody minWidth={minWidth}>{children}</SCCardBody>
}

export { CardBody }
