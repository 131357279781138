import { SCInputCurrency } from './styles'
import { IInputCurrency } from './types'

const InputCurrency: React.FC<IInputCurrency> = ({
  defaultValue,
  disabled,
  id,
  onChange
}: IInputCurrency) => {
  return (
    <SCInputCurrency
      defaultValue={defaultValue}
      disabled={disabled}
      id={id}
      key={defaultValue}
      onChange={({ target }) => {
        if (onChange) {
          onChange(parseFloat(target.value))
        }
      }}
      type='number'
    />
  )
}

export { InputCurrency }
