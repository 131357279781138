import { Fragment } from 'react'
import { SCLabel } from './styles'
import { ILabel } from './types'

const Label: React.FC<ILabel> = ({ children, htmlFor }: ILabel) => {
  return (
    <Fragment>
      <SCLabel htmlFor={htmlFor}>{children}</SCLabel>
    </Fragment>
  )
}

export { Label }
