import { Fragment } from 'react'
import { SCCardHeaderActions } from './styles'
import { ICardHeaderActions } from './types'

const CardHeaderActions: React.FC<ICardHeaderActions> = ({
  children
}: ICardHeaderActions) => {
  return (
    <Fragment>
      <SCCardHeaderActions>{children}</SCCardHeaderActions>
    </Fragment>
  )
}

export { CardHeaderActions }
