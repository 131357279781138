// Local - TypeScript Types
import { IStatRow } from './types'
// Local - Styled Components
import { SCStatRow } from './styles'

const StatRow: React.FC<IStatRow> = ({ children }: IStatRow) => {
  return <SCStatRow>{children}</SCStatRow>
}

export { StatRow }
