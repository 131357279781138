// Routing
import { Outlet } from 'react-router-dom'
// Icons
import {
  faChartBar,
  faMoneyBill,
  faMoneyCheck,
  faMoneyCheckAlt,
  faPiggyBank,
  faUniversity
} from '@fortawesome/free-solid-svg-icons'
import { faBitcoin } from '@fortawesome/free-brands-svg-icons'
// Global - Components
import { TopNavBar, TopNavLink } from 'components'

export const pagesMoney = [
  {
    className: 'tour-step-2',
    icon: faMoneyBill,
    title: 'Wallet',
    to: '/',
    bases: ['/fico', '/money', '/wallet', '/accounts/update-balances']
  },
  {
    className: 'tour-step-3',
    icon: faChartBar,
    title: 'Budget',
    to: '/forecast/ledger',
    bases: ['/forecast']
  },
  {
    icon: faMoneyCheck,
    title: 'Income',
    to: '/incomes/list',
    bases: ['/incomes']
  },
  {
    icon: faMoneyCheckAlt,
    title: 'Checking',
    to: '/checking-accounts/list',
    bases: ['/checking-accounts']
  },
  {
    icon: faPiggyBank,
    title: 'Savings',
    to: '/savings-accounts/list',
    bases: ['/savings-accounts']
  },
  {
    icon: faUniversity,
    title: 'Investments',
    to: '/investments/list',
    bases: ['/investments']
  },
  {
    icon: faBitcoin,
    title: 'Crypto',
    to: '/crypto/list',
    bases: ['/crypto']
  }
]

const LayoutMoney: React.FC = () => {
  return (
    <>
      <TopNavBar>
        <>
          {pagesMoney.map((page) => {
            return (
              <TopNavLink
                bases={page.bases}
                className={page.className}
                icon={page.icon}
                key={page.title}
                name={page.title}
                to={page.to}
              />
            )
          })}
        </>
      </TopNavBar>
      <Outlet />
    </>
  )
}

export default LayoutMoney
