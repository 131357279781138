import { Fragment } from 'react'
import { IPrettyCurrency } from './types'

const PrettyCurrency: React.FC<IPrettyCurrency> = ({
  value
}: IPrettyCurrency) => {
  const formatter = new Intl.NumberFormat('en', {
    style: 'currency',
    currency: 'USD'
  })
  return <Fragment>{formatter.format(value)}</Fragment>
}

export default PrettyCurrency

export const PrettyCurrencyAsString = ({ value }: IPrettyCurrency): string => {
  const formatter = new Intl.NumberFormat('en', {
    style: 'currency',
    currency: 'USD'
  })
  return formatter.format(value)
}
