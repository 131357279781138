// CSS
import 'reset-css'
import 'animate.css/animate.min.css'
import 'react-toastify/dist/ReactToastify.css'
import 'react-datepicker/dist/react-datepicker.css'
import 'react-big-calendar/lib/css/react-big-calendar.css'
import 'react-circular-progressbar/dist/styles.css'
// React
import { Fragment, Suspense } from 'react'
import ReactDOM from 'react-dom'
// Routing
import { BrowserRouter as Router } from 'react-router-dom'
import LayoutBase from './layouts/base'
import reportWebVitals from './reportWebVitals'

ReactDOM.render(
  <Suspense fallback={<Fragment>&nbsp;</Fragment>}>
    <Router basename={process.env.PUBLICURL}>
      <LayoutBase />
    </Router>
  </Suspense>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
