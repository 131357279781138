export const Institutions = [
  {
    name: 'Allowance',
    logo: require('assets/img/institutions/allowance.png')
  },
  {
    name: 'American Express',
    logo: require('assets/img/institutions/american-express.png')
  },
  {
    name: 'Apple TV',
    logo: require('assets/img/institutions/apple-tv.png')
  },
  {
    name: 'Astound',
    logo: require('assets/img/institutions/astound.png')
  },
  {
    name: 'Audible',
    logo: require('assets/img/institutions/audible.png')
  },
  {
    name: 'Avenue One Residential',
    logo: require('assets/img/institutions/avenue-one-residential.png')
  },
  {
    name: 'Azure',
    logo: require('assets/img/institutions/azure.png')
  },
  {
    name: 'Bank of America',
    logo: require('assets/img/institutions/bank-of-america.png')
  },
  {
    name: 'Bellevue Montessori',
    logo: require('assets/img/institutions/bellevue-montessori.png')
  },
  {
    name: 'BHG',
    logo: require('assets/img/institutions/bhg.png')
  },
  {
    name: 'Binance.us',
    logo: require('assets/img/institutions/binance-us.png')
  },
  {
    name: 'Capital One',
    logo: require('assets/img/institutions/capital-one.png')
  },
  {
    name: 'Cascade Natural Gas',
    logo: require('assets/img/institutions/cascade-natural-gas.png')
  },
  {
    name: 'Cash',
    logo: require('assets/img/institutions/cash.png')
  },
  {
    name: 'Chase',
    logo: require('assets/img/institutions/chase.png')
  },
  {
    name: 'Citi',
    logo: require('assets/img/institutions/citi.png')
  },
  {
    name: 'Coinbase',
    logo: require('assets/img/institutions/coinbase.png')
  },
  {
    name: 'Comcast Xfinity',
    logo: require('assets/img/institutions/comcast-xfinity.png')
  },
  {
    name: 'Costco',
    logo: require('assets/img/institutions/costco.png')
  },
  {
    name: 'Credit Karma',
    logo: require('assets/img/institutions/credit-karma.png')
  },
  {
    name: 'Discover',
    logo: require('assets/img/institutions/discover.png')
  },
  {
    name: 'Disney Plus',
    logo: require('assets/img/institutions/disney-plus.png')
  },
  {
    name: 'FreedomPlus',
    logo: require('assets/img/institutions/freedom-plus.png')
  },
  {
    name: 'Gasoline',
    logo: require('assets/img/institutions/gasoline.png')
  },
  {
    name: 'Geico',
    logo: require('assets/img/institutions/geico.png')
  },
  {
    name: 'GoDaddy',
    logo: require('assets/img/institutions/godaddy.png')
  },
  {
    name: 'Google',
    logo: require('assets/img/institutions/google.png')
  },
  {
    name: 'Groceries',
    logo: require('assets/img/institutions/groceries.png')
  },
  {
    name: 'Gusto',
    logo: require('assets/img/institutions/gusto.png')
  },
  {
    name: 'HAPO',
    logo: require('assets/img/institutions/hapo.png')
  },
  {
    name: 'Hello Bello',
    logo: require('assets/img/institutions/hello-bello.png')
  },
  {
    name: 'Hulu',
    logo: require('assets/img/institutions/hulu.png')
  },
  {
    name: 'IRS',
    logo: require('assets/img/institutions/irs.png')
  },
  {
    name: 'Jiko',
    logo: require('assets/img/institutions/jiko.png')
  },
  {
    name: 'Kay Jewelers',
    logo: require('assets/img/institutions/kay-jewelers.png')
  },
  {
    name: 'Life Storage',
    logo: require('assets/img/institutions/life-storage.png')
  },
  {
    name: 'Lowes',
    logo: require('assets/img/institutions/lowes.png')
  },
  {
    name: 'Mattress Firm',
    logo: require('assets/img/institutions/mattress-firm.png')
  },
  {
    name: 'Merrill Lynch',
    logo: require('assets/img/institutions/merrill-lynch.png')
  },
  {
    name: 'Nest',
    logo: require('assets/img/institutions/nest.png')
  },
  {
    name: 'Netflix',
    logo: require('assets/img/institutions/netflix.png')
  },
  {
    name: 'Orange County',
    logo: require('assets/img/institutions/orange-county.png')
  },
  {
    name: 'Original Pioneers',
    logo: require('assets/img/institutions/original-pioneers.png')
  },
  {
    name: 'Orlando Health',
    logo: require('assets/img/institutions/orlando-health.png')
  },
  {
    name: 'Overlake Hospital',
    logo: require('assets/img/institutions/overlake-hospital.svg')
  },
  {
    name: 'Puget Sound Energy',
    logo: require('assets/img/institutions/puget-sound-energy.png')
  },
  {
    name: 'Rent',
    logo: require('assets/img/institutions/rent.png')
  },
  {
    name: 'Renton WA',
    logo: require('assets/img/institutions/renton-wa.png')
  },
  {
    name: 'Republic Services',
    logo: require('assets/img/institutions/republic-services.png')
  },
  {
    name: 'Robinhood',
    logo: require('assets/img/institutions/robinhood.png')
  },
  {
    name: 'Rocket Mortgage',
    logo: require('assets/img/institutions/rocket-mortgage.png')
  },
  {
    name: 'Samsung Money',
    logo: require('assets/img/institutions/samsung-money.png')
  },
  {
    name: 'Siteground',
    logo: require('assets/img/institutions/siteground.png')
  },
  {
    name: 'Spotify',
    logo: require('assets/img/institutions/spotify.png')
  },
  {
    name: 'State Farm',
    logo: require('assets/img/institutions/state-farm.png')
  },
  {
    name: 'Swedish Health Services',
    logo: require('assets/img/institutions/swedish-health-services.png')
  },
  {
    name: 'Swedish Polyclinic',
    logo: require('assets/img/institutions/swedish-polyclinic.png')
  },
  {
    name: 'Toiletries',
    logo: require('assets/img/institutions/toiletries.png')
  },
  {
    name: 'TruStage',
    logo: require('assets/img/institutions/trustage.png')
  },
  {
    name: 'Toyota',
    logo: require('assets/img/institutions/toyota.jpg')
  },
  {
    name: 'Upgrade',
    logo: require('assets/img/institutions/upgrade.png')
  },
  {
    name: 'US Bank',
    logo: require('assets/img/institutions/us-bank.png')
  },
  {
    name: 'Valley Medical',
    logo: require('assets/img/institutions/valley-medical.png')
  },
  {
    name: 'Verizon Wireless',
    logo: require('assets/img/institutions/verizon.png')
  },
  {
    name: 'Waste Management',
    logo: require('assets/img/institutions/waste-management.png')
  },
  {
    name: 'West Sound Utility District',
    logo: require('assets/img/institutions/west-sound-utility-district.png')
  }
]
