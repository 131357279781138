import styled from 'styled-components'

export const SCButtonGroup = styled.div`
  display: flex;
  flex-direction: row;

  > * {
    margin-left: 5px;
  }
`
