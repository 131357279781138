// Routing
import { Outlet } from 'react-router-dom'
// Global - Components
import { CenterScreen } from 'components'
// Local - Styled Components
import { SCOnboard, SCOnboardBackgroundImage } from './styles'

const LayoutOnboard: React.FC = () => {
  return (
    <SCOnboard>
      <CenterScreen shadow>
        <Outlet />
      </CenterScreen>
      <SCOnboardBackgroundImage />
    </SCOnboard>
  )
}

export default LayoutOnboard
