import styled from 'styled-components'
import { IFlex } from './types'

export const SCFlex = styled.div<IFlex>`
  display: flex;
  width: 100%;
  ${(props) => props.flex !== undefined && `flex: ${String(props.flex)};`}
  ${(props) =>
    props.direction === 'row'
      ? `
      align-items: center;
      flex-direction: row;
  `
      : `
      flex-direction: column;
  `}
  ${(props) =>
    props.align === 'left'
      ? `
        align-items: start;
      `
      : props.align === 'center'
      ? `
      align-items: center;
      `
      : `
      align-items: end;
      `}
  ${(props) =>
    props.justify === 'right'
      ? `
            justify-content: end;
          `
      : props.justify === 'center'
      ? `
            justify-content: center;
          `
      : `
            justify-content: start;
          `}
    width: 100%;
`
