import { screenSizes } from 'config'
import styled from 'styled-components'
import { ICenterScreen } from './types'

export const SCCenterScreen = styled.div`
  align-self: center;
  display: flex;
  flex: 1;
  justify-self: center;
  z-index: 2;
`

export const SCCenterScreenWrapper = styled.div<ICenterScreen>`
  align-self: center;
  display: flex;
  flex-direction: column;
  ${(props) =>
    props.shadow &&
    `      
  border-radius: 15px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px,
    rgba(0, 0, 0, 0.3) 0px 18px 36px -18px;    
  `}

  @media (min-width: ${screenSizes.desktop}) {
    width: 500px;
  }
`
export const SCCenterScreenBackground = styled.div`
  background-image: url('https://images.unsplash.com/photo-1482784160316-6eb046863ece?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2070&q=80');
  background-position: 100%;
  background-size: cover;
  border-radius: 40px;
  border-top-left-radius: 0;
  bottom: 0;
  display: block;
  left: 240px;
  opacity: 0.25;
  position: fixed;
  top: 80px;
  width: 100%;
  z-index: 1;
`
