import { SCInputText } from './styles'
import { IInputText } from './types'

const InputText: React.FC<IInputText> = ({
  defaultValue,
  id,
  onChange
}: IInputText) => {
  return (
    <SCInputText
      defaultValue={defaultValue}
      id={id}
      key={defaultValue}
      onChange={({ target }) => {
        if (onChange) {
          onChange(target.value)
        }
      }}
      type='text'
    />
  )
}

export { InputText }
