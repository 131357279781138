// React
import { useState } from 'react'
// Routing
import { useNavigate } from 'react-router-dom'
// 3rd Party
import Loading from 'react-fullscreen-loading'
import { ToastContainer } from 'react-toastify'
import LogRocket from 'logrocket'
// Global - Config
import { colors } from 'config'
// Global - Context
import AppContext from 'context'
// Global - Styles
import { GlobalStyle } from './styles-global'
// Global - API's
import { APIAuthCurrentUser } from 'api/auth/current-user'
// Global - API Responses
import { APIAuthCurrentUserResponse } from 'api/auth/current-user/response'
import { AppRoutes } from 'routes'
import { useOnFirstLoad } from 'hooks'

LogRocket.init('du7lpn/finify-app')

const LayoutBase: React.FC = () => {
  const navigate = useNavigate()

  const [loadingUserDetails, setLoadingUserDetails] = useState<boolean>(true)
  const [loggedInUser, setLoggedInUser] = useState<APIAuthCurrentUserResponse>()

  const contextValue = {
    loggedInUser,
    setLoggedInUser
  }

  // Fires when the app first loads
  useOnFirstLoad(() => {
    APIAuthCurrentUser().then((apiResponse) => {
      if (apiResponse.wasSuccessful) {
        setLoggedInUser(apiResponse)
        setLoadingUserDetails(false)
        if (window.location.pathname.includes('auth')) {
          navigate('/')
        }
      } else {
        setLoggedInUser(undefined)
        setLoadingUserDetails(false)
        if (!window.location.pathname.includes('onboard')) {
          navigate('/auth/login')
        }
      }
    })
  })

  return (
    <AppContext.Provider value={contextValue}>
      <GlobalStyle />
      {loadingUserDetails ? (
        <Loading loaderColor={colors.secondary} loading={true} />
      ) : (
        <AppRoutes />
      )}
      <ToastContainer closeOnClick autoClose={5000} position='top-right' />
    </AppContext.Provider>
  )
}

export default LayoutBase
