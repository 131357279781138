// Local Styled Components
import { SCInputPassword } from './styles'
// Local TypeScript Types
import { IInputPassword } from './types'

const InputPassword: React.FC<IInputPassword> = ({
  defaultValue,
  id,
  onChange,
  onEnterKey
}: IInputPassword) => {
  return (
    <SCInputPassword
      defaultValue={defaultValue}
      id={id}
      key={defaultValue}
      onChange={({ target }) => {
        if (onChange) {
          onChange(target.value)
        }
      }}
      onKeyPress={(event) => {
        if (event.key === 'Enter') {
          if (onEnterKey) {
            onEnterKey()
          }
        }
      }}
      type='password'
    />
  )
}

export { InputPassword }
