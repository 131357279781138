import { faUserAlt, faUserShield } from '@fortawesome/free-solid-svg-icons'

export const NavLinksBottom = [
  {
    title: 'Your Profile',
    icon: faUserAlt,
    to: '/profile',
    bases: ['/profile']
  },
  {
    icon: faUserShield,
    title: 'Finify Advisors™',
    to: '/financial-advisors',
    bases: ['/financial-advisors']
  }
]
