import { screenSizes } from 'config'
import styled from 'styled-components'

export const SCRow = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;

  > :last-child {
    padding-right: 0;
  }

  @media (max-width: ${screenSizes.mobile}) {
    flex-wrap: wrap;
  }
`
