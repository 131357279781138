// Local - Styled Components
import { SCButtonGroup } from './styles'
// Local - TypeScript Types
import { IButtonGroup } from './types'

/** Displays a group of buttons horizontally with spacing. */
const ButtonGroup: React.FC<IButtonGroup> = ({ children }: IButtonGroup) => {
  return <SCButtonGroup>{children}</SCButtonGroup>
}

export { ButtonGroup }
