import styled from 'styled-components'

export const SCCardFooter = styled.div`
  background-color: #eff1f3;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  border-top: 1px solid #edf2f9;
  padding: 20px;
  user-select: none;
  width: 100%;
`
