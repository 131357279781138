// Styled Components
import styled from 'styled-components'
// Global - Config
import { colors } from 'config'
// Local - TypeScript Types
import { ISCAutoSync } from './types'

export const SCAutoSync = styled.div<ISCAutoSync>`
  align-items: center;
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  font-size: 10px;
  padding: 0 5px;

  ${(props) =>
    props.loading === 'true'
      ? `
  background-color: #f4f6fe;
  border: 1px solid ${colors.primary};
  opacity: 0.5;
  `
      : `
  
  background-color: rgb(248, 249, 250);
  border: 1px solid rgb(222, 226, 230);
  `}

  :hover {
    cursor: pointer;
  }

  svg {
    font-size: 10px;
  }
`
