// 3rd Party
import Select from 'react-select'
// Global Config
import { occurrenceTypes } from 'config'
// TypeScript Types
import {
  ISelectFinifyOccurrenceType,
  ISelectFinifyOccurrenceTypeOptions
} from './types'

const SelectFinifyOccurrenceType: React.FC<ISelectFinifyOccurrenceType> = ({
  defaultValue,
  onSelected
}: ISelectFinifyOccurrenceType) => {
  const options = occurrenceTypes.map((occurrenceType) => {
    return {
      label: occurrenceType.label,
      value: occurrenceType.value
    } as ISelectFinifyOccurrenceTypeOptions
  })
  let defaultOption
  if (defaultValue !== undefined) {
    const matchingOption = options.find((option) => {
      return option.value === defaultValue
    })
    if (matchingOption !== undefined) {
      defaultOption = matchingOption
    }
  }
  return (
    <Select
      isClearable
      classNamePrefix='finify-select'
      defaultValue={defaultOption}
      key={defaultOption?.value}
      onChange={(selectedOption) => {
        if (selectedOption?.value) {
          onSelected(selectedOption.value)
        } else {
          onSelected(undefined)
        }
      }}
      options={options}
    />
  )
}

export { SelectFinifyOccurrenceType }
