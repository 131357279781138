import {
  faBullseye,
  faChartBar,
  faMoneyBillAlt,
  faMoneyCheckAlt
} from '@fortawesome/free-solid-svg-icons'

export const NavLinksTop = [
  {
    icon: faMoneyBillAlt,
    title: 'Wallet',
    to: '/',
    bases: [
      '/wallet/update-balances',
      '/checking-accounts',
      '/crypto',
      '/fico',
      '/incomes',
      '/investments',
      '/money',
      '/savings-accounts'
    ]
  },
  {
    class: 'tour-step-3',
    icon: faChartBar,
    title: 'Budget',
    to: '/forecast/ledger',
    bases: ['/forecast']
  },
  {
    icon: faMoneyCheckAlt,
    title: 'Bills & Debts',
    to: '/debts',
    bases: [
      '/bills',
      '/credit-cards',
      '/debts',
      '/medical-debts',
      '/mortgages',
      '/other-debts',
      '/personal-loans',
      '/student-loans',
      '/tax-debts',
      '/vehicle-loans'
    ]
  },
  {
    icon: faBullseye,
    title: 'Goals',
    to: '/goals/list',
    bases: ['/goals']
  }
]
