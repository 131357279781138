// Styled Components
import styled from 'styled-components'
// Global - Config
import { screenSizes } from 'config'

export const SCStatRow = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: -50px;
  margin-bottom: 20px;

  @media (max-width: ${screenSizes.mobile}) {
    flex-wrap: wrap;
  }
`
