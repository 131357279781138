import styled from 'styled-components'

export const SCOnboard = styled.div`
  align-items: center;
  background-color: #edf2f9;
  display: flex;
  height: 100vh;
  left: 0;
  padding-bottom: 40px;
  padding-top: 40px;
  top: 0;
`

export const SCOnboardBackgroundImage = styled.div`
  background-image: url(https://images.unsplash.com/photo-1546084953-b839fd579e5b?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1922&q=80);
  background-position: 100%;
  background-size: cover;
  height: 100%;
  left: 0;
  opacity: 0.1;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1;
`
