import { SCInputCheckbox } from './styles'
import { IInputCheckbox } from './types'

const InputCheckbox: React.FC<IInputCheckbox> = ({
  checked,
  id,
  onChange
}: IInputCheckbox) => {
  return (
    <SCInputCheckbox
      checked={checked}
      id={id}
      onChange={({ target }) => {
        if (onChange) {
          onChange(parseFloat(target.value))
        }
      }}
      type='checkbox'
    />
  )
}

export { InputCheckbox }
