// Styled Components
import styled from 'styled-components'
// Global - Config
import { fonts } from 'config'
// Local - Assets - Images
import imgBackground from './assets/img/background.png'

export const SCPageTitle = styled.div`
  align-items: center;
  background-image: url('${imgBackground}');
  background-size: cover;
  border-radius: 15px;
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;
  padding: 40px;
  width: 100%;
`

export const SCPageTitleWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
  width: 100%;
`

export const SCPageTitleTitles = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`

export const SCPageTitleTitlesPrimary = styled.span`
  align-items: center;
  color: #fafafa;
  display: flex;
  font-family: '${fonts.primary}', sans-serif;
  font-size: 30px;
  font-weight: 500;
  line-height: 30px;
  margin-bottom: 20px;
`

export const SCPageTitleTitlesSecondary = styled.span`
  color: #fafafa;
  display: flex;
  font-family: '${fonts.primary}', sans-serif;
  font-size: 16px;
  font-weight: 300;
  line-height: 16px;

  a {
    color: #fafafa;
    text-decoration: none;
  }
`

export const SCPageTitleActions = styled.div`
  display: flex;
  flex-direction: row;
`

export const SCPageTitleBackgroundImage = styled.div``
