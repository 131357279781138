import { colors, screenSizes } from 'config'
import styled from 'styled-components'
import { ISCAppNavLeft } from './types'

const NavLeftWidth = '250px'

export const SCAppContainer = styled.div`
  display: flex;
  flex-direction: row;
  height: 100vh;
  width: 100vw;
`

export const SCAppNav = styled.div`
  display: flex;
  min-width: 240px;
  overflow-y: auto;
  width: 240px;

  ::-webkit-scrollbar {
    display: none;
  }

  ::-webkit-scrollbar-track {
    display: none;
  }

  ::-webkit-scrollbar-thumb {
    display: none;
  }
`

export const SCAppContentContainer = styled.div`
  background: #fff;
  border: 1px solid #dee2e6;
  border-radius: 40px 0px 0px 40px;
  box-shadow: 0 10px 30px 0 rgba(17, 38, 146, 0.05);
  display: flex;
  flex: 1;
  justify-content: center;
`
export const SCAppContent = styled.div`
  display: flex;
  flex-direction: column;
  margin: 5px 0;
  max-width: 100%;
  overflow-y: auto;
  padding: 20px;
  position: relative;
  scrollbar-width: auto;
  scrollbar-color: ${colors.primary} #ffffff;
  width: 100%;

  ::-webkit-scrollbar {
    width: 14px;
  }

  ::-webkit-scrollbar-track {
    background: #ffffff;
    border-left: 1px solid #dee2e6;
  }

  ::-webkit-scrollbar-thumb {
    background-color: ${colors.primary};
    border-radius: 10px;
    border: 3px solid #ffffff;
  }
`

export const SCAppContentScrollToTop = styled.span`
  background-color: #10c4b2;
  border-radius: 10px;
  bottom: 30px;
  color: #fafafa;
  cursor: pointer;
  font-size: 12px;
  padding: 10px;
  position: absolute;
  right: -10px;
`

export const SCAppNavLeftBackground = styled.div<ISCAppNavLeft>`
  cursor: pointer;
  display: none;
  height: 100vh;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 3;

  @media (max-width: ${screenSizes.tablet}) {
    ${(props) =>
      !props.isOpen
        ? `
        display: block;
    `
        : ''}
  }
`

/** Old */

export const SCAppRoot = styled.div`
  color: #333;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`
export const SCAppWrapper = styled.div`
  display: flex;
  flex-direction: row;
  min-height: 100vh;
  width: 100%;
`

export const SCAppNavLeft = styled.div<ISCAppNavLeft>`
  display: flex;
  min-height: 100vh;
  position: fixed;
  width: ${NavLeftWidth};

  @media (max-width: ${screenSizes.tablet}) {
    display: none;
    ${(props) =>
      !props.isOpen
        ? `
        display: block;
        z-index: 999;
    `
        : ''}
  }
`

export const SCAppContentOld = styled.div`
  align-items: flex-start;
  background: #fff;
  border-radius: 50px 0px 0px 50px;
  color: #888;
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-left: ${NavLeftWidth};
  padding: 20px;
  width: 100%;

  @media (max-width: ${screenSizes.tablet}) {
    margin-left: 0;
  }

  @media (max-width: ${screenSizes.mobile}) {
    max-width: 100vw;
  }
`
