// Local - Styled Components
import {
  SCCenterScreen,
  SCCenterScreenBackground,
  SCCenterScreenWrapper
} from './styles'
// Local - TypeScript Types
import { ICenterScreen } from './types'

/** Centers content horizontally and vertically on the screen. */
const CenterScreen: React.FC<ICenterScreen> = ({
  background,
  children,
  shadow
}: ICenterScreen) => {
  return (
    <>
      <SCCenterScreen>
        <SCCenterScreenWrapper shadow={shadow}>
          {children}
        </SCCenterScreenWrapper>
      </SCCenterScreen>
      {background && <SCCenterScreenBackground />}
    </>
  )
}

export { CenterScreen }
