import { createGlobalStyle } from 'styled-components'
// Local Styled Components
import {
  SCModal,
  SCModalBody,
  SCModalBodyContent,
  SCModalOverlay,
  SCModalTitle
} from './styles'
// Local TypeScript Types
import { IModal } from './types'

let GlobalStyle = createGlobalStyle``

const Modal: React.FC<IModal> = ({
  children,
  isOpen,
  onClose,
  title
}: IModal) => {
  if (isOpen) {
    GlobalStyle = createGlobalStyle`
    body, #finify-app-content  {
        overflow: hidden;
    }
    `
  } else {
    GlobalStyle = createGlobalStyle`
    body, #finify-app-content {
        overflow: auto;
    }
  `
  }

  return isOpen ? (
    <>
      <GlobalStyle />
      <SCModal>
        <SCModalBody
          className='animate__animated animate__bounceInDown animate__faster'
          onClick={() => {}}
        >
          {title && <SCModalTitle>{title}</SCModalTitle>}
          <SCModalBodyContent>{children}</SCModalBodyContent>
        </SCModalBody>
      </SCModal>
      <SCModalOverlay
        className='animate__animated animate__fadeIn animate__faster'
        onClick={() => {
          if (onClose) {
            onClose()
          }
        }}
      />
    </>
  ) : (
    <GlobalStyle />
  )
}

export { Modal }
