import { HTTP } from 'config'
import { APIAccountsListRequest } from './request'
import { APIAccountsListResponse } from './response'

export async function APIAccountsList(
  apiRequest: APIAccountsListRequest
): Promise<APIAccountsListResponse> {
  let response = {} as APIAccountsListResponse

  await HTTP.get('accounts/list', { params: apiRequest })
    .then((res) => {
      if (res.status === 200 && res.data) {
        response = res.data
      }
    })
    .catch(() => {
      // means no response returned
    })
  return response
}
