// 3rd Party - React Toastify
import { toast } from 'react-toastify'
// Local TypeScript Types
import { IToast } from './types'

const Toast: ({ content, type }: IToast) => void = ({
  content,
  type
}: IToast) => {
  switch (type) {
    case 'success':
      toast.success(content)
      break
    case 'error':
      toast.error(content)
      break
    case 'warning':
      toast.warning(content)
      break
    case 'info':
    default:
      toast.info(content)
      break
  }
}

export { Toast }
