// React
import { lazy } from 'react'
// Routing
import { Navigate, Route, Routes } from 'react-router-dom'
// Layouts
import LayoutApp from 'layouts/app'
import LayoutAuth from 'layouts/auth'
import LayoutErrors from 'layouts/errors'
import LayoutOnboard from 'layouts/onboard'
import LayoutMoney from 'layouts/money'
import LayoutDebts from 'layouts/debts'
import LayoutProfile from 'layouts/profile'
/* App - Auth */
const PageAuthLogin = lazy(async () => await import('pages/auth/login'))
const PageAuthRegister = lazy(async () => await import('pages/auth/register'))
/* App - Forecast */
const PageForecastCalendar = lazy(
  async () => await import('pages/forecast/calendar')
)
const PageForecastLedger = lazy(
  async () => await import('pages/forecast/ledger')
)
/* App - Checking */
const PageCheckingAccountsCreate = lazy(
  async () => await import('pages/checking-accounts/create')
)
const PageCheckingAccountsDetails = lazy(
  async () => await import('pages/checking-accounts/details')
)
const PageCheckingAccountsList = lazy(
  async () => await import('pages/checking-accounts/list')
)
/* App - Savings */
const PageSavingsAccountCreate = lazy(
  async () => await import('pages/savings-accounts/create')
)
const PageSavingsAccountDetails = lazy(
  async () => await import('pages/savings-accounts/details')
)
const PageSavingsAccountList = lazy(
  async () => await import('pages/savings-accounts/list')
)
/* App - Income */
const PageIncomesCreate = lazy(async () => await import('pages/income/create'))
const PageIncomesDetails = lazy(
  async () => await import('pages/income/details')
)
const PageIncomesList = lazy(async () => await import('pages/income/list'))
/* App - Bills */
const PageBillsCreate = lazy(async () => await import('pages/bills/create'))
const PageBillsDetails = lazy(async () => await import('pages/bills/details'))
const PageBillsList = lazy(async () => await import('pages/bills/list'))
/* App - Vehicle Loans */
const PageVehicleLoansCreate = lazy(
  async () => await import('pages/vehicle-loans/create')
)
const PageVehicleLoansDetails = lazy(
  async () => await import('pages/vehicle-loans/details')
)
const PageVehicleLoansList = lazy(
  async () => await import('pages/vehicle-loans/list')
)
/* App - Credit Cards */
const PageCreditCardsCreate = lazy(
  async () => await import('pages/credit-cards/create')
)
const PageCreditCardsDetails = lazy(
  async () => await import('pages/credit-cards/details')
)
const PageCreditCardsList = lazy(
  async () => await import('pages/credit-cards/list')
)
const PageCreditCardsPayoffSnowball = lazy(
  async () => await import('pages/credit-cards/payoff/snowball')
)
/* App - Personal Loans */
const PagePersonalLoansCreate = lazy(
  async () => await import('pages/personal-loans/create')
)
const PagePersonalLoansDetails = lazy(
  async () => await import('pages/personal-loans/details')
)
const PagePersonalLoansList = lazy(
  async () => await import('pages/personal-loans/list')
)
/* App - Pages - Medical Debts */
const PageMedicalDebtsCreate = lazy(
  async () => await import('pages/medical-debts/create')
)
const PageMedicalDebtsDetails = lazy(
  async () => await import('pages/medical-debts/details')
)
const PageMedicalDebtsList = lazy(
  async () => await import('pages/medical-debts/list')
)
/* App - Pages - Accounts */
const PageAccountsCreate = lazy(
  async () => await import('pages/accounts/create')
)
const PageAccountsDetails = lazy(
  async () => await import('pages/accounts/details')
)
const PageAccountsList = lazy(async () => await import('pages/accounts/list'))
const PageAccountsUpdateBalances = lazy(
  async () => await import('pages/accounts/update-balances')
)
/* App - Pages  - Goals */
const PageGoalsCreate = lazy(async () => await import('pages/goals/create'))
const PageGoalsDetails = lazy(async () => await import('pages/goals/details'))
const PageGoalsList = lazy(async () => await import('pages/goals/list'))
/* App - Pages  - Crypto */
const PageCryptoCreate = lazy(async () => await import('pages/crypto/create'))
const PageCryptoDetails = lazy(async () => await import('pages/crypto/details'))
const PageCryptoList = lazy(async () => await import('pages/crypto/list'))
/* App - Pages  - Reports */
const PageReportsMonth = lazy(async () => await import('pages/reports/month'))
const PageReportsQuarter = lazy(
  async () => await import('pages/reports/quarter')
)
const PageReportsYearToDate = lazy(
  async () => await import('pages/reports/year-to-date')
)
/* App - Pages - FICO */
const PageFICO = lazy(async () => await import('pages/fico'))
/* App - Pages - Error */
const Page404 = lazy(async () => await import('pages/error/404'))
const PageError = lazy(async () => await import('pages/error/err'))
/* App - Pages - Onboarding */
const PageOnboard = lazy(async () => await import('pages/onboard'))
/* App - Pages - Student Loans */
const PageStudentLoansCreate = lazy(
  async () => await import('pages/student-loans/create')
)
const PageStudentLoansDetails = lazy(
  async () => await import('pages/student-loans/details')
)
const PageStudentLoansList = lazy(
  async () => await import('pages/student-loans/list')
)
/* App - Pages - Investments */
const PageInvestmentsCreate = lazy(
  async () => await import('pages/investments/create')
)
const PageInvestmentsDetails = lazy(
  async () => await import('pages/investments/details')
)
const PageInvestmentsList = lazy(
  async () => await import('pages/investments/list')
)
/* App - Pages - Other Debts */
const PageOtherDebtsCreate = lazy(
  async () => await import('pages/other-debts/create')
)
const PageOtherDebtsDetails = lazy(
  async () => await import('pages/other-debts/details')
)
const PageOtherDebtsList = lazy(
  async () => await import('pages/other-debts/list')
)
/* App - Pages - Tax Debts */
const PageTaxDebtsCreate = lazy(
  async () => await import('pages/tax-debts/create')
)
const PageTaxDebtsDetails = lazy(
  async () => await import('pages/tax-debts/details')
)
const PageTaxDebtsList = lazy(async () => await import('pages/tax-debts/list'))
/* TODO Routes */

const PageProfile = lazy(async () => await import('pages/profile'))
const PageProfileFriendsAndFamily = lazy(
  async () => await import('pages/profile/friends-and-family')
)
const PageProfileSyncedAccounts = lazy(
  async () => await import('pages/profile/synced-accounts')
)
const PageMortgagesList = lazy(async () => await import('pages/mortgages/list'))
const PageTransfersList = lazy(async () => await import('pages/transfers/list'))
const PageFinancialAdvisors = lazy(
  async () => await import('pages/financial-advisors')
)
const PageAI = lazy(async () => await import('pages/ai'))
const PageFinifyCard = lazy(async () => await import('pages/finify-plus'))
const PageMoney = lazy(async () => await import('pages/money'))
const PageDebts = lazy(async () => await import('pages/debts'))

export const AppRoutes: React.FC = () => {
  return (
    <Routes>
      {/* Error Routes */}
      <Route element={<LayoutErrors />} path='errors'>
        <Route element={<PageError />} path='err' />
        <Route element={<Page404 />} path='404' />
      </Route>
      {/* Onboarding Routes */}
      <Route element={<LayoutOnboard />} path='onboarding'>
        <Route element={<PageOnboard />} path='' />
      </Route>
      {/* Auth Routes */}
      <Route element={<LayoutAuth />} path='auth'>
        <Route element={<PageAuthLogin />} path='login' />
        <Route element={<PageAuthRegister />} path='register' />
      </Route>
      {/* App Routes */}
      <Route element={<LayoutApp />} path='/*'>
        {/* Routes - Accounts */}
        <Route element={<PageAccountsCreate />} path='accounts/create' />
        <Route element={<PageAccountsList />} path='accounts/list' />
        <Route element={<PageAccountsDetails />} path='accounts/details/:id' />
        {/* Routes - Transfers */}
        <Route element={<PageTransfersList />} path='transfers/list' />
        {/* Routes - AI */}
        <Route element={<PageAI />} path='ai' />
        {/* Routes - Layout - Money */}
        <Route element={<LayoutMoney />} path=''>
          {/* Routes - Index */}
          <Route element={<PageMoney />} path='' />
          {/* Routes - Welcome */}
          <Route element={<PageMoney />} path='welcome/:welcome' />
          {/* Routes - Update Balances */}
          <Route
            element={<PageAccountsUpdateBalances type='cash' />}
            path='wallet/update-balances'
          />
          {/* Routes - Forecast */}
          <Route element={<PageForecastLedger />} path='forecast/ledger' />
          <Route element={<PageForecastCalendar />} path='forecast/calendar' />
          {/* Routes - FICO */}
          <Route element={<PageFICO />} path='fico' />
          {/* Routes - Incomes */}
          <Route element={<PageIncomesList />} path='incomes/list' />
          <Route element={<PageIncomesCreate />} path='incomes/create' />
          <Route element={<PageIncomesDetails />} path='incomes/details/:id' />
          {/* Routes - Crypto */}
          <Route element={<PageCryptoCreate />} path='crypto/create' />
          <Route element={<PageCryptoDetails />} path='crypto/details/:id' />
          <Route element={<PageCryptoList />} path='crypto/list' />
          {/* Routes - Investments */}
          <Route
            element={<PageInvestmentsCreate />}
            path='investments/create'
          />
          <Route
            element={<PageInvestmentsDetails />}
            path='investments/details/:id'
          />
          <Route element={<PageInvestmentsList />} path='investments/list' />
          {/* Routes - Checking Accounts */}
          <Route
            element={<PageCheckingAccountsList />}
            path='checking-accounts/list'
          />
          <Route
            element={<PageCheckingAccountsCreate />}
            path='checking-accounts/create'
          />
          <Route
            element={<PageCheckingAccountsDetails />}
            path='checking-accounts/details/:id'
          />
          {/* Routes - Savings Accounts */}
          <Route
            element={<PageSavingsAccountCreate />}
            path='savings-accounts/create'
          />
          <Route
            element={<PageSavingsAccountDetails />}
            path='savings-accounts/details/:id'
          />
          <Route
            element={<PageSavingsAccountList />}
            path='savings-accounts/list'
          />
        </Route>
        {/* Routes - Layout - Debts */}
        <Route element={<LayoutDebts />} path=''>
          <Route element={<PageDebts />} path='debts' />
          {/* Routes - Update Balances */}
          <Route
            element={<PageAccountsUpdateBalances type='debts' />}
            path='debts/update-balances'
          />
          {/* Routes - Student Loans */}
          <Route
            element={<PageStudentLoansCreate />}
            path='student-loans/create'
          />
          <Route
            element={<PageStudentLoansDetails />}
            path='student-loans/details/:id'
          />
          <Route element={<PageStudentLoansList />} path='student-loans/list' />
          {/* Routes - Student Loans */}
          <Route element={<PageTaxDebtsCreate />} path='tax-debts/create' />
          <Route
            element={<PageTaxDebtsDetails />}
            path='tax-debts/details/:id'
          />
          <Route element={<PageTaxDebtsList />} path='tax-debts/list' />
          {/* Routes - Other Debts */}
          <Route element={<PageOtherDebtsCreate />} path='other-debts/create' />
          <Route
            element={<PageOtherDebtsDetails />}
            path='other-debts/details/:id'
          />
          <Route element={<PageOtherDebtsList />} path='other-debts/list' />
          {/* Routes - Bills */}
          <Route element={<PageBillsList />} path='bills/list' />
          <Route element={<PageBillsCreate />} path='bills/create' />
          <Route element={<PageBillsDetails />} path='bills/details/:id' />

          {/* Routes - Personal Loans */}
          <Route
            element={<PagePersonalLoansList />}
            path='personal-loans/list'
          />
          <Route
            element={<PagePersonalLoansCreate />}
            path='personal-loans/create'
          />
          <Route
            element={<PagePersonalLoansDetails />}
            path='personal-loans/details/:id'
          />
          {/* Routes - Mortgages */}
          <Route element={<PageMortgagesList />} path='mortgages/list' />
          {/* Routes - Vehicle Loans */}
          <Route element={<PageVehicleLoansList />} path='vehicle-loans/list' />
          <Route
            element={<PageVehicleLoansCreate />}
            path='vehicle-loans/create'
          />
          <Route
            element={<PageVehicleLoansDetails />}
            path='vehicle-loans/details/:id'
          />
          {/* Routes - Medical Debts */}
          <Route element={<PageMedicalDebtsList />} path='medical-debts/list' />
          <Route
            element={<PageMedicalDebtsCreate />}
            path='medical-debts/create'
          />
          <Route
            element={<PageMedicalDebtsDetails />}
            path='medical-debts/details/:id'
          />
          {/* Routes - Credit Cards */}
          <Route element={<PageCreditCardsList />} path='credit-cards/list' />
          <Route
            element={<PageCreditCardsCreate />}
            path='credit-cards/create'
          />
          <Route
            element={<PageCreditCardsDetails />}
            path='credit-cards/details/:id'
          />
          <Route
            element={<PageCreditCardsPayoffSnowball />}
            path='credit-cards/payoff/snowball'
          />
        </Route>
        {/* Routes - Layout - Profile */}
        <Route element={<LayoutProfile />} path=''>
          {/* Routes - Profile */}
          <Route element={<PageProfile />} path='profile' />
          <Route
            element={<PageProfileFriendsAndFamily />}
            path='friends-and-family'
          />
          <Route
            element={<PageProfileSyncedAccounts />}
            path='synced-accounts'
          />
          {/* Routes - Goals */}
          <Route element={<PageGoalsCreate />} path='goals/create' />
          <Route element={<PageGoalsDetails />} path='goals/details/:id' />
          <Route element={<PageGoalsList />} path='goals/list' />
          {/* Routes - Financial Advisors */}
          <Route
            element={<PageFinancialAdvisors />}
            path='financial-advisors'
          />
          {/* Routes - Finify Card */}
          <Route element={<PageFinifyCard />} path='finify-plus' />
        </Route>
        {/* Routes - Reports */}
        <Route element={<PageReportsMonth />} path='reports/month' />
        <Route element={<PageReportsQuarter />} path='reports/quarter' />
        <Route
          element={<PageReportsYearToDate />}
          path='reports/year-to-date'
        />
        {/* Routes - FICO */}
        <Route element={<Navigate to='/errors/404' />} path='*' />
      </Route>
    </Routes>
  )
}
