// Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
// Global Config
import { colors } from 'config'
// Local Styled Components
import { SCSpinner } from './styles'
// TypeScript Types
import { ISpinner } from './types'

const Spinner: React.FC<ISpinner> = ({ size }: ISpinner) => {
  return (
    <SCSpinner>
      <FontAwesomeIcon
        spin
        color={colors.secondary}
        icon={faSpinner}
        size={size}
      />
    </SCSpinner>
  )
}

export { Spinner }
