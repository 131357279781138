import { Fragment } from 'react'
import { SCCard } from './styles'
import { ICard } from './types'

const Card: React.FC<ICard> = ({ children }: ICard) => {
  return (
    <Fragment>
      <SCCard>{children}</SCCard>
    </Fragment>
  )
}

export { Card }
