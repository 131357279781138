import { CenterScreen, Spinner } from 'components'
import { SCLoader } from './styles'

const Loader: React.FC = () => {
  return (
    <CenterScreen shadow={false}>
      <SCLoader>
        <Spinner size='10x' />
      </SCLoader>
    </CenterScreen>
  )
}

export { Loader }
