import { useLocation } from 'react-router-dom'
// Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// Local - Styled Components
import { SCTopNavLink, SCTopNavLinkIcon, SCTopNavLinkText } from './styles'
// Local - TypeScript Types
import { ITopNavLink } from './types'

const TopNavLink: React.FC<ITopNavLink> = ({
  bases,
  className,
  icon,
  name,
  to
}: ITopNavLink) => {
  const location = useLocation()
  const isActive =
    bases.some((base) => location.pathname.startsWith(base)) ||
    location.pathname === to
  return (
    <>
      <SCTopNavLink active={isActive} className={className} to={to}>
        <SCTopNavLinkIcon>
          <FontAwesomeIcon icon={icon} />
        </SCTopNavLinkIcon>
        <SCTopNavLinkText>{name}</SCTopNavLinkText>
      </SCTopNavLink>
    </>
  )
}

export { TopNavLink }
