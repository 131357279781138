// React
import { useMemo } from 'react'
// 3rd Party
import { Chart } from 'react-charts'
// Components
import { PrettyDateTime, PrettyCurrency } from 'components'
// Local TypeScript Types
import { IChartLine } from './types'

const ChartLine: React.FC<IChartLine> = ({ height, chartData }: IChartLine) => {
  const axes = useMemo(
    () => [
      { primary: true, type: 'utc', position: 'bottom' },
      { type: 'linear', position: 'left' }
    ],
    []
  )

  const series = useMemo(
    () => ({
      type: 'line'
    }),
    []
  )

  const tooltip = useMemo(
    () => ({
      render: ({ datum }: any) => {
        return datum ? (
          <>
            <PrettyDateTime
              datetime={
                datum.originalDatum[0]
                  ? datum.originalDatum[0]
                  : datum.originalDatum.x
              }
              format='ddd MMM Do YYYY'
            />
            <br />
            {datum.originalSeries.label}
            <br />
            <PrettyCurrency
              value={
                datum.originalDatum[1]
                  ? datum.originalDatum[1]
                  : datum.originalDatum.y
              }
            />
          </>
        ) : (
          <></>
        )
      }
    }),
    []
  )

  return (
    <div
      style={{
        overflow: 'hidden',
        width: '100%',
        height: `${height}px`
      }}
    >
      {chartData && chartData.length > 0 ? (
        <Chart axes={axes} data={chartData} series={series} tooltip={tooltip} />
      ) : (
        'No Chart Data'
      )}
    </div>
  )
}

export { ChartLine }
