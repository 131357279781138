import { colors, fonts } from 'config'
import styled from 'styled-components'

export const SCStepperTitle = styled.div`
  background: rgb(22, 181, 165);
  background: linear-gradient(
    90deg,
    rgb(255 255 255) 0%,
    rgb(239 241 243) 35%,
    rgb(255 255 255) 100%
  );
  border: 1px solid #cccccc;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  color: ${colors.primary};
  font-family: '${fonts.primary}', sans-serif;
  font-weight: 500;
  margin-top: -35px;
  padding: 10px;
  padding-bottom: 20px;
  position: absolute;
  z-index: -1;
`
