// Styled Components
import styled from 'styled-components'
// Global App Config
import { fonts } from 'config'

export const SCPrettyInstitutionWrapper = styled.div`
  background-color: #fff;
  border: 1px solid #dee2e6;
  padding: 5px;
  text-align: center;
  width: 100px;
`

export const SCPrettyInstitutionImage = styled.img`
  max-height: 20px;
  max-width: 100%;
`
export const SCPrettyInstitutionLink = styled.a`
  background-color: rgb(248, 249, 250);
  border: 1px solid rgb(222, 226, 230);
  color: #878787;
  display: block;
  font-family: '${fonts.primary}', sans-serif;
  font-size: 11px;
  padding: 5px;
  text-align: center;
  text-decoration: none;
`
