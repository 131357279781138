// 3rd Party
import CreatableSelect from 'react-select/creatable'
// Local Data
import { Institutions } from 'data/institutions'
// TypeScript Types
import {
  ISelectFinifyInstitution,
  ISelectFinifyInstitutionOptions
} from './types'
// Component
import { Flex, PrettyInstitution } from 'components'

const SelectFinifyInstitution: React.FC<ISelectFinifyInstitution> = ({
  defaultValue,
  onSelected
}: ISelectFinifyInstitution) => {
  const options = Institutions.map((institution) => {
    return {
      label: institution.name,
      value: institution.name,
      logo: institution.logo
    } as ISelectFinifyInstitutionOptions
  })
  let defaultOption
  if (defaultValue !== undefined) {
    const matchingOption = options.find((option) => {
      return option.value === defaultValue
    })
    if (matchingOption !== undefined) {
      defaultOption = matchingOption
    }
  }
  return (
    <CreatableSelect
      isClearable
      classNamePrefix='finify-select'
      defaultValue={defaultOption}
      formatOptionLabel={(option: ISelectFinifyInstitutionOptions) => (
        <Flex>
          {option.logo && <PrettyInstitution institution={option.label} />}
          <span style={{ paddingLeft: 20 }}>{option.label}</span>
        </Flex>
      )}
      key={defaultOption?.value}
      onChange={(selectedOption) => {
        if (selectedOption?.value) {
          onSelected(selectedOption.value)
        } else {
          onSelected(undefined)
        }
      }}
      options={options}
    />
  )
}

export { SelectFinifyInstitution }
