// React
import { useEffect, useState } from 'react'

/** Fires one time when it is first loaded. */
const useOnFirstLoad = (event: () => void): void => {
  const [firstLoad, setFirstLoad] = useState(true)
  useEffect(() => {
    if (event) {
      if (firstLoad) {
        setFirstLoad(false)
        event()
      }
    }
  }, [firstLoad, event])
}

export { useOnFirstLoad }
