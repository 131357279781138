import { HTTP } from 'config'
import { APIAuthCurrentUserResponse } from './response'

export async function APIAuthCurrentUser(): Promise<APIAuthCurrentUserResponse> {
  let response = {} as APIAuthCurrentUserResponse
  const token = window.localStorage.getItem('userToken')
  if (token) {
    // Add a request interceptor
    HTTP.interceptors.request.use(function (config) {
      config.headers.Authorization = 'Bearer ' + token
      return config
    })
  }
  await HTTP.get('auth/currentUser', {
    headers: {
      Authorization: `Bearer ${token}`
    }
  })
    .then((res) => {
      if (res.status === 200 && res.data) {
        response = res.data
      }
    })
    .catch(() => {
      // means no response returned
    })
  return response
}
