import { fonts } from 'config'
import styled from 'styled-components'

export const SCFieldSet = styled.div`
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-top: 25px;
  padding: 20px;
  width: 100%;
`

export const SCFieldSetTitle = styled.div`
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  color: #363940;
  font-family: '${fonts.primary}', sans-serif;
  font-size: 1.15rem;
  font-weight: 500;
  margin-top: -45px;
  padding: 5px 10px;
  position: absolute;
`

export const SCFieldSetContent = styled.div``
