// Routing
import { Outlet } from 'react-router-dom'
// Global - Components
import { CenterScreen } from 'components'
// Local - Styled Components
import {
  SCLayoutAuthBackground,
  SCLayoutAuthContent,
  SCLayoutAuthWrapper
} from './styles'

const LayoutAuth: React.FC = () => {
  return (
    <SCLayoutAuthWrapper>
      <SCLayoutAuthContent>
        <CenterScreen shadow>
          <Outlet />
        </CenterScreen>
      </SCLayoutAuthContent>
      <SCLayoutAuthBackground />
    </SCLayoutAuthWrapper>
  )
}

export default LayoutAuth
