import styled from 'styled-components'

export const SCLayoutAuthWrapper = styled.div`
  align-items: center;
  display: flex;
  height: 100vh;
  justify-content: center;
  width: 100vw;
`

export const SCLayoutAuthBackground = styled.div`
  background-image: url('https://images.unsplash.com/photo-1482784160316-6eb046863ece?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2070&q=80');
  background-position: 100%;
  background-size: cover;
  display: block;
  height: 100%;
  left: 0;
  opacity: 0.25;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1;
`

export const SCLayoutAuthContent = styled.div`
  display: flex;
  max-width: 600px;
  position: relative;
  z-index: 2;
`
