import { HTTP } from 'config'
import { APIAccountsSyncTriggerRequest } from './request'
import { APIAccountsSyncTriggerResponse } from './response'

export async function APIAccountsSyncTrigger(
  apiRequest: APIAccountsSyncTriggerRequest
): Promise<APIAccountsSyncTriggerResponse> {
  let response = {} as APIAccountsSyncTriggerResponse

  await HTTP.post('accounts/sync/trigger', apiRequest)
    .then((res) => {
      if (res.status === 200 && res.data) {
        response = res.data
      }
    })
    .catch(() => {
      // means no response returned
    })
  return response
}
