// React
import React from 'react'
// Global - Components
import { ProgressCircle } from 'components'
// Local - TypeScript Type
import { IStatCard } from './types'
// Local - Styled Components
import {
  SCStatCard,
  SCStatCardContent,
  SCStatCardContentDescription,
  SCStatCardContentTitle,
  SCStatCardGraph
} from './styles'

const StatCard: React.FC<IStatCard> = ({
  description,
  graphValue,
  title
}: IStatCard) => {
  return (
    <>
      <SCStatCard>
        {graphValue > 0 && (
          <SCStatCardGraph>
            <ProgressCircle
              text={`${graphValue.toFixed(0)}%`}
              value={graphValue}
            />
          </SCStatCardGraph>
        )}
        <SCStatCardContent>
          <SCStatCardContentTitle>{title}</SCStatCardContentTitle>
          <SCStatCardContentDescription>
            {description}
          </SCStatCardContentDescription>
        </SCStatCardContent>
      </SCStatCard>
    </>
  )
}

export { StatCard }
