// React
import { useEffect, useState } from 'react'
// 3rd Party
import Select from 'react-select'
// API's
import { APIAccountsList } from 'api/accounts/list'
// Local TypeScript Types
import { ISelectFinifyAccount, ISelectFinifyAccountOptions } from './types'
import { Flex, PrettyInstitution } from 'components'

const SelectFinifyAccount: React.FC<ISelectFinifyAccount> = ({
  defaultValue,
  onSelected
}: ISelectFinifyAccount) => {
  const [firstLoad, setFirstLoad] = useState<boolean>(true)
  const [accountOptions, setAccountOptions] = useState<
    ISelectFinifyAccountOptions[]
  >([])
  const [defaultOption, setDefaultOption] = useState<
    ISelectFinifyAccountOptions | undefined
  >(undefined)

  useEffect(() => {
    if (firstLoad) {
      setFirstLoad(false)
      APIAccountsList({}).then((apiResponse) => {
        const options = apiResponse.accounts.map((account) => {
          return {
            institution: account.institution,
            label: account.name,
            value: account.id
          } as ISelectFinifyAccountOptions
        })
        setAccountOptions(options)
      })
    }
  }, [firstLoad])

  useEffect(() => {
    if (defaultValue !== undefined) {
      const matchingOption = accountOptions.find((option) => {
        return option.value === defaultValue
      })
      if (matchingOption !== undefined) {
        setDefaultOption(matchingOption)
      }
    }
  }, [defaultValue, accountOptions])

  return (
    <Select
      isClearable
      classNamePrefix='finify-select'
      defaultValue={defaultOption}
      formatOptionLabel={(option: ISelectFinifyAccountOptions) => (
        <Flex>
          {option.institution && (
            <PrettyInstitution institution={option.institution} />
          )}
          <span style={{ paddingLeft: 20 }}>{option.label}</span>
        </Flex>
      )}
      key={defaultOption?.value}
      onChange={(selectedOption) => {
        if (selectedOption?.value) {
          onSelected(selectedOption.value)
        } else {
          onSelected(undefined)
        }
      }}
      options={accountOptions}
    />
  )
}

export { SelectFinifyAccount }
