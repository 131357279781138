// 3rd Party
import { useState } from 'react'
import DatePicker from 'react-datepicker'
import { SCInputDate } from './styles'
// Local TypeScript Types
import { IInputDate } from './types'

const InputDate: React.FC<IInputDate> = ({
  defaultValue,
  id,
  maxDate,
  minDate,
  onChange
}: IInputDate) => {
  const [selectedDate, setSelectedDate] = useState<Date | undefined>(undefined)
  return (
    <SCInputDate>
      <DatePicker
        id={id}
        key={defaultValue?.toString()}
        maxDate={maxDate}
        minDate={minDate}
        onChange={(date) => {
          if (date) {
            if (onChange) {
              const valueAsDate = new Date(date.toString())
              onChange(valueAsDate)
              setSelectedDate(valueAsDate)
            }
          }
        }}
        selected={
          selectedDate || (defaultValue && new Date(defaultValue.toString()))
        }
      />
    </SCInputDate>
  )
}

export { InputDate }
