import { Fragment } from 'react'
import { SCCardHeader } from './styles'
import { ICardHeader } from './types'

const CardHeader: React.FC<ICardHeader> = ({ children }: ICardHeader) => {
  return (
    <Fragment>
      <SCCardHeader>{children}</SCCardHeader>
    </Fragment>
  )
}

export { CardHeader }
