// Styled Components
import { createGlobalStyle } from 'styled-components'
// Global App Config
import { colors, fonts } from 'config'

export const GlobalStyle = createGlobalStyle`
  html,
  body {
    background-color: #f8f6ff;
    box-sizing: border-box;
    font-family: '${fonts.secondary}', sans-serif;
    font-size: 16px;
    -webkit-font-smoothing: antialiased;
    line-height: 100%;
    margin: 0;
    min-width: 320px;

    ::-webkit-scrollbar {
      width: 12px;
    }

    ::-webkit-scrollbar-thumb {
      box-shadow: rgba(40, 58, 80, 0.65) 4px 4px 4px 4px inset;
      cursor: pointer !important;
    }

    ::-webkit-scrollbar-track {
      box-shadow: rgba(0, 0, 0, 0.65) 0 0 6px inset;
    }
  }

  hr {
    width: 100%;
  }

  label {
    font-family: '${fonts.primary}', sans-serif;
    font-size: 1.10rem;
    font-weight: 600;
  }

  li {
    list-style: bullet;
    margin: 5px 0 5px 15px;
  }

  p {
    font-family: '${fonts.secondary}', sans-serif;
    font-size: 1.0rem;
    font-weight: 400;
    line-height: 125%;
  }

  small {
    font-size: 0.85em;
  }

  strong {
    font-family: '${fonts.primary}', sans-serif;
    font-size: 1.10rem;
    font-weight: 600;
  }

  .finify-select__control {
    height: 50px;
  }

  .finify-select__menu {
    z-index: 9 !important;
  }

  .finify-select__option {
    cursor: pointer !important;
    font-family: '${fonts.secondary}', sans-serif;
    font-size: 15px;
    font-weight: 500;
  }

  .finify-select__option--is-focused {
    background-color: rgba(16, 196, 178, 0.10) !important;
    color: ${colors.primary} !important;
  }
  
  .finify-select__option--is-selected {
    background-color: ${colors.secondary} !important;
    color: #FAFAFA !important;
  }

  .finify-select__clear-indicator {
    cursor: pointer !important;
  }

  *,
  ::after,
  ::before {
    box-sizing: border-box;
  }
`
