// Styled Components
import styled from 'styled-components'
// Routing
import { Link } from 'react-router-dom'
import { fonts } from 'config'
import { ISCTopNavLink } from './types'

export const SCTopNavLink = styled(Link)<ISCTopNavLink>`
  align-items: center;
  border: 2px solid transparent;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  padding: 10px 20px;
  text-decoration: none;
  transition: all 0.2s ease-in-out;
  ${(props) =>
    props.active
      ? `
      box-shadow: 0 10px 30px 0 rgba(17, 38, 146, 0.05);
      color: #12c5b3;

      ::after{
        background-color: #12c5b3;
        content: '';        
        height: 2px;
        margin-top:5px;
        width: 100%;
        border: 2px solid #12c5b3;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
      }
      `
      : `
      color: #2a3c51;
      
      :hover {
        border: 2px solid rgba(115, 99, 210, 0.18);
        box-shadow: 0 10px 30px 0 rgba(17, 38, 146, 0.05);
        color: #7363d2;
      }
  `}
`

export const SCTopNavLinkIcon = styled.div`
  display: flex;
`

export const SCTopNavLinkText = styled.div`
  display: block;
  font-family: '${fonts.primary}', sans-serif;
  margin-top: 5px;
  width: max-content;
`
