// Styled Components
import styled from 'styled-components'
// Global - Config
import { fonts, screenSizes } from 'config'

export const SCStatCard = styled.div`
  background-color: #fafafa;
  border: 1px solid #dee2e6;
  border-radius: 15px;
  box-shadow: 0 10px 30px 0 rgba(17, 38, 146, 0.05);
  display: flex;
  flex-direction: row;
  margin-left: 20px;
  padding: 1.5rem;

  @media (max-width: ${screenSizes.mobile}) {
    width: 100%;
  }
`

export const SCStatCardGraph = styled.div`
  display: flex;
  height: 50px;
  padding-right: 20px;
`

export const SCStatCardContent = styled.div`
  display: flex;
  flex-direction: column;
`

export const SCStatCardContentTitle = styled.span`
  color: rgb(138, 146, 166);
  display: flex;
  font-family: '${fonts.primary}', sans-serif;
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 5px;
`

export const SCStatCardContentDescription = styled.span`
  color: #232d42;
  display: flex;
  font-family: '${fonts.primary}', sans-serif;
  font-size: 25px;
  font-weight: 600;
`
