// 3rd Party
import PProgressBar from '@ramonak/react-progress-bar'
// Global Config
import { colors } from 'config'
// Local TypeScript Types
import { IProgressBar } from './types'

const ProgressBar: React.FC<IProgressBar> = ({ amount }: IProgressBar) => {
  return (
    <PProgressBar
      animateOnRender
      bgColor={colors.secondary}
      completed={amount}
      labelAlignment='outside'
      labelColor={colors.secondary}
    />
  )
}

export { ProgressBar }
