import { SCButton } from './styles'
import { IButton } from './types'
import { Spinner } from 'components'

const Button: React.FC<IButton> = ({
  block,
  children,
  color,
  disabled,
  loading,
  onClick
}: IButton) => {
  return (
    <SCButton
      block={block ?? false}
      color={color ?? 'default'}
      disabled={loading ?? disabled}
      onClick={onClick}
      tabIndex={-1}
    >
      {loading && <Spinner />}
      {children}
    </SCButton>
  )
}

export { Button }
