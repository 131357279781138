import { SCInputSwitch } from './styles'
import { IInputSwitch } from './types'

const InputSwitch: React.FC<IInputSwitch> = ({
  defaultValue,
  onChange
}: IInputSwitch) => {
  return (
    <SCInputSwitch
      defaultChecked={defaultValue}
      key={defaultValue.toString()}
      onChange={({ target }) => {
        if (onChange) {
          onChange(target.checked)
        }
      }}
      type='checkbox'
    />
  )
}

export { InputSwitch }
