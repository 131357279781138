// React
import { useState } from 'react'
// Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faAngleDoubleLeft,
  faAngleDoubleRight
} from '@fortawesome/free-solid-svg-icons'
// Global - Components
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  CardHeaderTitle,
  Flex
} from 'components'
// Local - TypeScript Types
import { IStepper } from './types'
// Local - Styled Components
import { SCStepperTitle } from './styles'

const Stepper: React.FC<IStepper> = ({
  children,
  finalButton,
  title
}: IStepper) => {
  const [currentStep, setCurrentStep] = useState<number>(0)
  return (
    <>
      {title && <SCStepperTitle>{title}</SCStepperTitle>}
      <Card>
        <CardHeader>
          <CardHeaderTitle>
            Step {currentStep + 1} of {children.length}
          </CardHeaderTitle>
        </CardHeader>
        <CardBody>{children[currentStep]}</CardBody>
        <CardFooter>
          <Flex>
            <Flex flex={1}>
              <Button
                disabled={currentStep === 0}
                onClick={() => {
                  setCurrentStep((previous) => previous - 1)
                }}
              >
                <FontAwesomeIcon icon={faAngleDoubleLeft} />
                &nbsp;Previous
              </Button>
            </Flex>
            <Flex flex={1} justify='right'>
              {currentStep < children.length - 1 ? (
                <Button
                  onClick={() => {
                    setCurrentStep((previous) => previous + 1)
                  }}
                >
                  Next&nbsp;
                  <FontAwesomeIcon icon={faAngleDoubleRight} />
                </Button>
              ) : (
                finalButton
              )}
            </Flex>
          </Flex>
        </CardFooter>
      </Card>
    </>
  )
}

export { Stepper }
