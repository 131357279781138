import { SCFlex } from './styles'
import { IFlex } from './types'

const Flex: React.FC<IFlex> = ({
  align,
  children,
  direction,
  flex,
  justify
}: IFlex) => {
  return (
    <SCFlex
      align={align ?? 'center'}
      direction={direction ?? 'row'}
      flex={flex}
      justify={justify ?? 'left'}
    >
      {children}
    </SCFlex>
  )
}

export { Flex }
