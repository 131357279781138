import { ReactElement, useState } from 'react'
import { SCTabs, SCTabsPane, SCTabsTab, SCTabsTabRow } from './styles'
import { ITab } from './Tab/types'
import { ITabs } from './types'

const Tabs: React.FC<ITabs> = ({ children }: ITabs) => {
  const [selectedTab, setSelectedTab] = useState<number>(0)

  return (
    <SCTabs>
      <SCTabsTabRow>
        {children.map((child: ReactElement<ITab>, index: number) => {
          return (
            <SCTabsTab
              active={selectedTab === index ? 'true' : 'false'}
              key={index}
              onClick={() => {
                setSelectedTab(index)
              }}
            >
              {child.props.title}
            </SCTabsTab>
          )
        })}
      </SCTabsTabRow>
      <SCTabsPane>{children[selectedTab]}</SCTabsPane>
    </SCTabs>
  )
}

export { Tabs }
