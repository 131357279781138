// Styled Components
import styled from 'styled-components'
// Global App Config
import { fonts } from 'config'

export const SCCardHeaderTitle = styled.div`
  align-items: center;
  color: #363940;
  display: flex;
  flex-direction: column;
  font-family: '${fonts.primary}', sans-serif;
  font-size: 1.5rem;
  font-weight: 500;
  height: 100%;
  justify-content: center;
  line-height: 1.5rem;

  @media (min-width: 768px) {
    font-size: 1.2rem;
    line-height: 1.2rem;
  }

  small {
    font-size: 0.85rem;
    line-height: 0.85rem;
  }
`
