// 3rd Party - React Gauge Chart
import GaugeChart from 'react-gauge-chart'
// Local - TypeScript Types
import { IChartGauge } from './types'

const ChartGauge: React.FC<IChartGauge> = ({ percent }: IChartGauge) => {
  return (
    <>
      <GaugeChart percent={percent} textColor='#2a3c51' />
    </>
  )
}

export { ChartGauge }
