import { screenSizes } from 'config'
import styled from 'styled-components'

export const SCCardHeader = styled.div`
  align-items: center;
  border-bottom: 1px solid #edf2f9;
  display: flex;
  flex-direction: row;
  padding: 20px;

  @media (max-width: ${screenSizes.mobile}) {
    flex-wrap: wrap;
  }
`
