// Images
import imgWorkInProgress from 'assets/img/work-in-progress.png'
// Local Styled Components
import { SCInProgress } from './styles'

const InProgress: React.FC = () => {
  return (
    <SCInProgress>
      <strong>
        <img
          alt='Work In Progress'
          src={imgWorkInProgress}
          style={{ maxHeight: 50 }}
        />
        &nbsp;Work Still In Progress!
      </strong>
      <p>
        The dev team is stil buidling this feature. Please come back later to
        see what you can do with this feature.
      </p>
    </SCInProgress>
  )
}

export { InProgress }
