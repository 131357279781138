import { Fragment } from 'react'
import { SCCardHeaderTitle } from './styles'
import { ICardHeaderTitle } from './types'

const CardHeaderTitle: React.FC<ICardHeaderTitle> = ({
  children
}: ICardHeaderTitle) => {
  return (
    <Fragment>
      <SCCardHeaderTitle>{children}</SCCardHeaderTitle>
    </Fragment>
  )
}

export { CardHeaderTitle }
