import axios from 'axios'

const userToken = localStorage.getItem('userToken')
export const CancelToken = axios.CancelToken

// =========
// Set our default api URL and token management
// =========
export const baseURL = 'https://finifyapi.azurewebsites.net'
// export const baseURL = 'https://localhost:44396'

export const apiURL = '/api'
export const plaidPubicKey = 'd6be1d0f596e0b556814988f0aaf7e'
export const plaidEnvironment = 'development'

const today = new Date()
const timezoneOffset = today.getTimezoneOffset()

// Set Axios defaults
export const HTTP = axios.create({
  baseURL: baseURL + apiURL,
  headers: {
    Authorization: `Bearer ${userToken ?? ''}`,
    TimezoneOffset: (-1 * timezoneOffset).toString()
  }
})

export const fonts = {
  primary: 'Rubik',
  secondary: 'Inter'
}

export const colors = {
  primary: '#283a50',
  secondary: '#6667AB',
  tertiary: '#00859b',
  success: '#c7efcf',
  error: '#ffe4df',
  warning: '#fff07c',
  white: '#fafafa'
}

export const screenSizes = {
  mobile: '425px',
  tablet: '922px',
  desktop: '1024px'
}

export const accountTypes = [
  {
    label: 'Checking',
    value: 'Checking'
  },
  {
    label: 'Savings',
    value: 'Savings'
  },
  {
    label: 'Credit',
    value: 'Credit'
  },
  {
    label: 'Loan',
    value: 'Loan'
  }
]

export const occurrenceTypes = [
  {
    label: 'Day',
    value: 'Day'
  },
  {
    label: 'Week',
    value: 'Week'
  },
  {
    label: 'Month',
    value: 'Month'
  },
  {
    label: 'Year',
    value: 'Year'
  }
]

export const weekDays = [
  {
    label: 'Monday',
    value: 'Monday'
  },
  {
    label: 'Tuesday',
    value: 'Tuesday'
  },
  {
    label: 'Wednesday',
    value: 'Wednesday'
  },
  {
    label: 'Thursday',
    value: 'Thursday'
  },
  {
    label: 'Friday',
    value: 'Friday'
  },
  {
    label: 'Saturday',
    value: 'Saturday'
  },
  {
    label: 'Sunday',
    value: 'Sunday'
  }
]

export const paymentTypes = [
  {
    label: 'Automatic',
    value: 'Automatic'
  },
  {
    label: 'Manual',
    value: 'Manual'
  }
]

export const debtTypes = [
  {
    label: 'CreditCard',
    value: 'CreditCard'
  },
  {
    label: 'AutoLoan',
    value: 'AutoLoan'
  },
  {
    label: 'MedicalDebt',
    value: 'MedicalDebt'
  },
  {
    label: 'StoreCard',
    value: 'StoreCard'
  },
  {
    label: 'PersonalLoan',
    value: 'PersonalLoan'
  }
]
