// Routing
import { Outlet } from 'react-router-dom'
// Global Components
import { CenterScreen } from 'components'
// Local Styled Components
import { SCLayoutErrorWrapper } from './styles'

const LayoutError: React.FC = () => {
  return (
    <SCLayoutErrorWrapper>
      <CenterScreen shadow>
        <Outlet />
      </CenterScreen>
    </SCLayoutErrorWrapper>
  )
}

export default LayoutError
