// React
import { Suspense, useEffect, useState } from 'react'
// 3rd Party
import ScrollToTop from 'react-scroll-up'
// Routing
import { useLocation, Outlet } from 'react-router-dom'
// Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowUp } from '@fortawesome/free-solid-svg-icons'
// Local Styled Components
import {
  SCAppContainer,
  SCAppNav,
  SCAppContentContainer,
  SCAppContent,
  SCAppContentScrollToTop,
  SCAppNavLeftBackground
} from './styles'
// Local Components
import { Nav } from './components/Nav'
import { Loader } from 'components'

const LayoutApp: React.FC = () => {
  const location = useLocation()
  const [firstLoad, setFirstLoad] = useState<boolean>(true)
  const [menuIsOpen, setMenuIsOpen] = useState<boolean>(true)

  // Runs on first render
  useEffect(() => {
    if (firstLoad) {
      const mq = window.matchMedia('(min-width: 767px)')
      // If window is less than 767px, set menuIsOpen to false
      if (mq.matches) {
        setMenuIsOpen(false)
      }
      setFirstLoad(false)
    }
  }, [firstLoad])

  // Runs anytime the route changes
  useEffect(() => {
    // Scroll to the top of the page
    window.scrollTo(0, 0)
  }, [location])

  return (
    <SCAppContainer>
      <SCAppNav className='tour-step-1'>
        <Nav
          onMobileMenuClick={() => {
            setMenuIsOpen((previous) => !previous)
          }}
        />
      </SCAppNav>
      {menuIsOpen && <SCAppNavLeftBackground isOpen={menuIsOpen} />}
      <SCAppContentContainer>
        <SCAppContent id='finify-app-content'>
          <Suspense fallback={<Loader />}>
            <Outlet />
          </Suspense>
          <ScrollToTop duration={100} showUnder={100}>
            <SCAppContentScrollToTop>
              <FontAwesomeIcon icon={faArrowUp} />
            </SCAppContentScrollToTop>
          </ScrollToTop>
        </SCAppContent>
      </SCAppContentContainer>
    </SCAppContainer>
  )
}

export default LayoutApp
