// Local Styled Components
import { SCFieldSet, SCFieldSetContent, SCFieldSetTitle } from './styles'
// Local TypeScript Types
import { IFieldSet } from './types'

const FieldSet: React.FC<IFieldSet> = ({ children, title }: IFieldSet) => {
  return (
    <SCFieldSet>
      <SCFieldSetTitle>{title}</SCFieldSetTitle>
      <SCFieldSetContent>{children}</SCFieldSetContent>
    </SCFieldSet>
  )
}

export { FieldSet }
