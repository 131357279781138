import Moment from 'react-moment'
import 'moment-timezone'
import { IPrettyDateTime } from './types'

const PrettyDateTime: React.FC<IPrettyDateTime> = ({
  datetime,
  dateOnly,
  format,
  utc
}: IPrettyDateTime) => {
  return (
    <Moment
      format={
        format ?? dateOnly ? 'ddd, MMM Do, YYYY' : 'ddd, MMM Do, YYYY hh:mm A'
      }
      local={!utc}
      utc={utc ?? false}
    >
      {datetime}
    </Moment>
  )
}

export default PrettyDateTime
