// Local TypeScript Types
import { IFICORange } from './types'
import { SCFicoRange } from './styles'

const FicoRange: React.FC<IFICORange> = ({ range }: IFICORange) => {
  return (
    <SCFicoRange
      className='text-center m-0'
      style={{
        border: '1px solid #FFF',
        color:
          range >= 0 && range <= 9
            ? '#FFF'
            : range >= 10 && range <= 29
            ? '#FFF'
            : range >= 30 && range <= 49
            ? '#FFF'
            : range >= 50 && range <= 74
            ? '#FFF'
            : range >= 75
            ? '#FFF'
            : '',
        background:
          range >= 0 && range <= 9
            ? '#28a745'
            : range >= 10 && range <= 29
            ? '#ffc107'
            : range >= 30 && range <= 49
            ? '#ffc107'
            : range >= 50 && range <= 74
            ? '#ff5f00'
            : range >= 75
            ? '#cd0013'
            : ''
      }}
    >
      {range >= 0 && range <= 9 && '0%-9% - Perfect'}
      {range >= 10 && range <= 29 && '10%-29% - Good'}
      {range >= 30 && range <= 49 && '30%-49% - Needs Improvement'}
      {range >= 50 && range <= 74 && '50%-74% - In Danger'}
      {range >= 75 && '75%+ - Too High'}
    </SCFicoRange>
  )
}

export { FicoRange }
