import styled from 'styled-components'
import { ICardBody } from './types'

export const SCCardBody = styled.div<ICardBody>`
  background: rgb(255, 255, 255);
  background: linear-gradient(
    21deg,
    rgb(255, 255, 255) 0%,
    rgb(0, 133, 155, 0.05) 100%
  );
  border-radius: 0px 0px 15px 15px;
  display: flex;
  flex-direction: column;
  ${(props) =>
    props.minWidth !== undefined && `min-width: ${String(props.minWidth)}px;`}
  padding: 20px;
`
