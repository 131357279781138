// Routing
import { Outlet } from 'react-router-dom'
// Icons
import {
  faBullseye,
  faCrown,
  faSync,
  faUser,
  faUsers,
  faUserShield
} from '@fortawesome/free-solid-svg-icons'
// Global - Components
import { TopNavBar, TopNavLink } from 'components'

export const pagesProfile = [
  {
    icon: faUser,
    title: 'Your Profile',
    to: '/profile',
    bases: ['/profile']
  },
  {
    icon: faBullseye,
    title: 'Goals',
    to: '/goals/list',
    bases: ['/goals']
  },
  {
    icon: faUsers,
    title: 'Friends & Family',
    to: '/friends-and-family',
    bases: ['/friends']
  },
  {
    icon: faSync,
    title: 'Synced Accounts',
    to: '/synced-accounts',
    bases: ['/synced-accounts']
  },
  {
    icon: faCrown,
    title: 'Finify Plus™',
    to: '/finify-plus',
    bases: ['/finify-plus']
  },
  {
    icon: faUserShield,
    title: 'Finify Advisors™',
    to: '/financial-advisors',
    bases: ['/financial-advisors']
  }
]

const LayoutProfile: React.FC = () => {
  return (
    <>
      <TopNavBar>
        <>
          {pagesProfile.map((page) => {
            return (
              <TopNavLink
                bases={page.bases}
                icon={page.icon}
                key={page.title}
                name={page.title}
                to={page.to}
              />
            )
          })}
        </>
      </TopNavBar>
      <Outlet />
    </>
  )
}

export default LayoutProfile
