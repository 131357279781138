import { Fragment } from 'react'
import { IPrettyPaymentType } from './types'

const PrettyPaymentType: React.FC<IPrettyPaymentType> = ({
  paymentType
}: IPrettyPaymentType) => {
  return (
    <Fragment>
      {paymentType === 'Automatic'
        ? 'Automatically'
        : paymentType === 'Manual'
        ? 'Manually'
        : ''}
    </Fragment>
  )
}

export default PrettyPaymentType
