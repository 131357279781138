import { SCTable, SCTableWrapper } from './styles'
import { ITable } from './types'

const Table: React.FC<ITable> = ({ children }: ITable) => {
  return (
    <SCTableWrapper>
      <SCTable>{children}</SCTable>
    </SCTableWrapper>
  )
}

export { Table }
