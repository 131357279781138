// React
import { useState } from 'react'
// Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSync } from '@fortawesome/free-solid-svg-icons'
// Global - API's
import { APIAccountsSyncTrigger } from 'api/accounts/sync/trigger'
// Global - Components
import { Toast } from 'components'
// Local - TypeScript Types
import { IAutoSync } from './types'
// Local - Styled Components
import { SCAutoSync } from './styles'

const AutoSync: React.FC<IAutoSync> = ({
  accountId,
  onSyncCompleted
}: IAutoSync) => {
  const [loading, setLoading] = useState<boolean>(false)

  const handleAccountBalanceRefresh: () => void = () => {
    setLoading(true)
    const apiRequest = {
      accountId
    }
    APIAccountsSyncTrigger(apiRequest).then((apiResponse) => {
      if (apiResponse.wasSuccessful) {
        Toast({
          content: 'Successfully refreshed your bank account balance!',
          type: 'success'
        })
        if (onSyncCompleted) {
          onSyncCompleted()
        }
      } else {
        Toast({
          content: 'Failed to update your bank account balance!',
          type: 'error'
        })
      }
      setLoading(false)
    })
  }
  return (
    <SCAutoSync
      loading={loading ? 'true' : 'false'}
      onClick={handleAccountBalanceRefresh}
    >
      <FontAwesomeIcon className='d-block' icon={faSync} spin={loading} />
      &nbsp;Finify Sync™
    </SCAutoSync>
  )
}

export { AutoSync }
