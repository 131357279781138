import { colors, fonts } from 'config'
import styled from 'styled-components'
import { ISCTabsTab } from './types'

export const SCTabs = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

export const SCTabsTabRow = styled.div`
  display: flex;
  flex-direction: row;
`
export const SCTabsTab = styled.div<ISCTabsTab>`
  align-items: center;
  background-color: #fff;
  border: 1px solid #edf2f9;
  border-bottom: 3px solid #edf2f9;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  display: flex;
  font-family: '${fonts.primary}', sans-serif;
  font-size: 1rem;
  font-weight: 500;
  justify-content: center;
  line-height: 1rem;
  padding: 10px 20px;
  transition: all 0.2s ease-in-out;
  user-select: none;
  ${(props) =>
    props.active === 'true'
      ? `
        color: #12c5b3;
        border-bottom: 3px solid #12c5b3;
      `
      : `
        color: #c5c5c5;

        :hover {
          background-color: ${colors.white};
          border: 1px solid rgba(115, 99, 210, 0.18);
          border-bottom: 3px solid #d2ceee;
          box-shadow: 0 10px 30px 0 rgba(17, 38, 146, 0.05);
          color: #7363d2;
          cursor: pointer;
        }
    `}
`
export const SCTabsPane = styled.div`
  background-color: ${colors.white};
  border: 1px solid #edf2f9;
  border-radius: 15px;
  border-top-left-radius: 0;
  display: flex;
  flex: 1;
  padding: 15px;
  width: 100%;
`
