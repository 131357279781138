// Styled Components
import styled from 'styled-components'
// Global Config
import { fonts } from 'config'

export const SCLabel = styled.label`
  color: #2a3c51;
  font-family: '${fonts.primary}', sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 14px;
  margin-bottom: 5px;
`
