import { SCInputNumber } from './styles'
import { IInputNumber } from './types'

const InputNumber: React.FC<IInputNumber> = ({
  defaultValue,
  id,
  max,
  min,
  onChange
}: IInputNumber) => {
  return (
    <SCInputNumber
      defaultValue={String(defaultValue)}
      id={id}
      key={defaultValue}
      max={max}
      min={min}
      onChange={({ target }) => {
        if (onChange) {
          onChange(parseFloat(target.value))
        }
      }}
      type='number'
    />
  )
}

export { InputNumber }
