// Local Styled Components
import { SCFormGroup } from './styles'
// Local TypeScript Types
import { IFormGroup } from './types'

const FormGroup: React.FC<IFormGroup> = ({
  children,
  marginBottom
}: IFormGroup) => {
  return <SCFormGroup marginBottom={marginBottom}>{children}</SCFormGroup>
}

export { FormGroup }
