// Routing
import { Link, useLocation } from 'react-router-dom'
// Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCrown } from '@fortawesome/free-solid-svg-icons'
// Global - Assets - Images
import imgLogo from 'assets/img/finify-logo-teal.png'
// Global - Data
import { NavLinksBottom } from 'data/nav-links-bottom'
import { NavLinksTop } from 'data/nav-links-top'
// Local - Components
import { Button } from 'components'
// Local - Styled Components
import {
  SCNav,
  SCNavBottom,
  SCNavCard,
  SCNavCardLogo,
  SCNavHeader,
  SCNavHeaderLogo,
  SCNavLink,
  SCNavLinkIcon,
  SCNavLinkText,
  SCNavTop
} from './styles'
// Local - TypeScript Types
import { INav } from './types'

const Nav: React.FC<INav> = ({ onMobileMenuClick }: INav) => {
  const location = useLocation()
  return (
    <>
      <SCNav>
        <SCNavHeader>
          <Link to='/'>
            <SCNavHeaderLogo alt='Finify Logo' src={imgLogo} />
          </Link>
        </SCNavHeader>
        <SCNavTop>
          {NavLinksTop.map((navLink) => {
            const isActive =
              navLink.bases.some((base) =>
                location.pathname.startsWith(base)
              ) || navLink.to === location.pathname
            return (
              <SCNavLink active={isActive} key={navLink.title} to={navLink.to}>
                <SCNavLinkIcon>
                  <FontAwesomeIcon icon={navLink.icon} />
                </SCNavLinkIcon>
                <SCNavLinkText>{navLink.title}</SCNavLinkText>
              </SCNavLink>
            )
          })}
        </SCNavTop>
        <SCNavBottom>
          {NavLinksBottom.map((navLink) => {
            const isActive =
              navLink.bases.some((base) =>
                location.pathname.startsWith(base)
              ) || navLink.to === location.pathname
            return (
              <SCNavLink active={isActive} key={navLink.title} to={navLink.to}>
                <SCNavLinkIcon>
                  <FontAwesomeIcon icon={navLink.icon} />
                </SCNavLinkIcon>
                <SCNavLinkText>{navLink.title}</SCNavLinkText>
              </SCNavLink>
            )
          })}
          <SCNavCard>
            <SCNavCardLogo alt='Finify Logo' src={imgLogo} />
            <strong>Automate</strong>
            Your Finances
            <br />
            <br />
            <Link to='/finify-plus'>
              <Button color='default'>
                <FontAwesomeIcon icon={faCrown} />
                &nbsp;Finify Plus™
              </Button>
            </Link>
          </SCNavCard>
        </SCNavBottom>
      </SCNav>
    </>
  )
}

export { Nav }
