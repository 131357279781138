// Local - Styled Components
import { SCBadge } from './styles'
// Local - TypeScript Types
import { IBadge } from './types'

/** Renders a small text "badge" component with a variety of color options */
const Badge: React.FC<IBadge> = ({ children, color }: IBadge) => {
  return <SCBadge color={color ?? 'default'}>{children}</SCBadge>
}

export { Badge }
