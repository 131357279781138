// Local - Styled Components
import {
  SCTopNavBar,
  SCTopNavBarContainer,
  SCTopNavBarPad,
  SCTopNavBarWrapper
} from './styles'
// Local - TypeScript Types
import { ITopNavBar } from './types'

const TopNavBar: React.FC<ITopNavBar> = ({ children }: ITopNavBar) => {
  return (
    <>
      <SCTopNavBarContainer>
        <SCTopNavBarWrapper>
          <SCTopNavBar>{children}</SCTopNavBar>
        </SCTopNavBarWrapper>
      </SCTopNavBarContainer>
      <SCTopNavBarPad />
    </>
  )
}

export { TopNavBar }
