// 3rd Party - React Circular Progress Bar
import { CircularProgressbar } from 'react-circular-progressbar'
// Local - TypeScript Types
import { IProgressCircle } from './types'

const ProgressCircle: React.FC<IProgressCircle> = ({
  text,
  value
}: IProgressCircle) => {
  return (
    <>
      <CircularProgressbar text={text} value={value} />
    </>
  )
}

export { ProgressCircle }
