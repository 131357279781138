// Routing
import { Outlet } from 'react-router-dom'
// Icons
import {
  faHome,
  faCreditCard,
  faCarSide,
  faHandHoldingUsd,
  faFileMedicalAlt,
  faUserGraduate,
  faFunnelDollar,
  faCoins,
  faCalendar
} from '@fortawesome/free-solid-svg-icons'
// Global - Components
import { TopNavBar, TopNavLink } from 'components'

export const pagesDebts = [
  {
    icon: faCalendar,
    title: 'Bills',
    to: '/bills/list',
    bases: ['/bills', '/debts']
  },
  {
    icon: faHome,
    title: 'Mortgages',
    to: '/mortgages/list',
    bases: ['/mortgages']
  },
  {
    icon: faCarSide,
    title: 'Vehicle Loans',
    to: '/vehicle-loans/list',
    bases: ['/vehicle-loans']
  },
  {
    icon: faCreditCard,
    title: 'Credit Cards',
    to: '/credit-cards/list',
    bases: ['/credit-cards']
  },
  {
    icon: faHandHoldingUsd,
    title: 'Personal Loans',
    to: '/personal-loans/list',
    bases: ['/personal-loans']
  },
  {
    icon: faUserGraduate,
    title: 'Student Loans',
    to: '/student-loans/list',
    bases: ['/student-loans']
  },
  {
    icon: faFileMedicalAlt,
    title: 'Medical Debts',
    to: '/medical-debts/list',
    bases: ['/medical-debts']
  },
  {
    icon: faCoins,
    title: 'Tax Debts',
    to: '/tax-debts/list',
    bases: ['/tax-debts']
  },
  {
    icon: faFunnelDollar,
    title: 'Other',
    to: '/other-debts/list',
    bases: ['/other-debts']
  }
]

const LayoutDebts: React.FC = () => {
  return (
    <>
      <TopNavBar>
        <>
          {pagesDebts.map((page) => {
            return (
              <TopNavLink
                bases={page.bases}
                icon={page.icon}
                key={page.title}
                name={page.title}
                to={page.to}
              />
            )
          })}
        </>
      </TopNavBar>
      <Outlet />
    </>
  )
}

export default LayoutDebts
