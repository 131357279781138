// Styled Components
import styled from 'styled-components'
// Global App Config
import { colors, fonts } from 'config'

export const SCTableWrapper = styled.div`
  overflow-x: auto;
  width: 100%;
`

export const SCTable = styled.table`
  background-color: #fff;
  box-shadow: 0 10px 30px 0 rgba(17, 38, 146, 0.05);
  width: 100%;

  thead {
    tr {
      background-color: rgb(250, 250, 250);
    }

    th {
      background-color: rgb(250, 250, 250);
      border-left: 1px solid #dee2e6;
      border-right: 1px solid #dee2e6;
      border-top: 1px solid #dee2e6;
      color: #212529;
      font-family: '${fonts.primary}', sans-serif;
      font-size: 1.05rem;
      font-weight: 500;
      padding: 10px;
      text-align: left;
    }
  }

  tbody {
    background-color: #fff;

    tr {
      background-color: #fff;
    }

    td {
      background-color: #fff;
      border: 1px solid #dee2e6;
      color: #212529;
      font-family: '${fonts.primary}', sans-serif;
      font-size: 1rem;
      font-weight: 400;
      padding: 10px;
      text-align: left;
      vertical-align: middle;
    }
  }

  tfoot {
    background-color: #fff;

    tr {
      background-color: #fff;
    }

    td,
    th {
      background-color: #fff;
    }
  }

  td.fit,
  th.fit {
    white-space: nowrap;
    width: 1%;
  }

  .table-danger,
  .table-danger > td,
  .table-danger > th {
    background-color: rgba(255, 166, 158, 0.07);
    border-color: #ed969e;
    color: #b94238;
  }

  .table-success,
  .table-success > td,
  .table-success > th {
    background-color: ${colors.success};
    border-color: #8db573;
    color: #2e6e29;
  }
`
