// React
import { useCallback, useState } from 'react'
// 3rd Party
import { usePlaidLink } from 'react-plaid-link'
// Global Config
import { plaidEnvironment, plaidPubicKey } from 'config'
// Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faExchangeAlt,
  faPlug,
  faPlus,
  faUniversity
} from '@fortawesome/free-solid-svg-icons'
// Components
import { Button, Card, CardBody, Modal, Table } from 'components'
// Local Types
import { IPlaidLinker, IPlaidLinkerAccount } from './types'

const PlaidLinker: React.FC<IPlaidLinker> = ({
  onAccountSelected
}: IPlaidLinker) => {
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [plaidPublicToken, setPlaidPublicToken] = useState<string>('')
  const [plaidAccounts, setPlaidAccounts] = useState([])

  const handleAccountSelected: (account: IPlaidLinkerAccount) => void = (
    account: IPlaidLinkerAccount
  ) => {
    onAccountSelected(plaidPublicToken, account.id)
    setIsOpen(false)
  }

  const onSuccess = useCallback((token, metadata) => {
    setPlaidPublicToken(token)
    setPlaidAccounts(metadata.accounts)
    setIsOpen(true)
  }, [])

  const config = {
    clientName: 'Finify',
    env: plaidEnvironment,
    product: ['auth', 'transactions'],
    publicKey: plaidPubicKey,
    onSuccess
  }

  const { open, ready } = usePlaidLink(config)

  return (
    <>
      <Button disabled={!ready} onClick={() => open()}>
        <FontAwesomeIcon icon={faPlug} />
        &nbsp;
        <FontAwesomeIcon icon={faPlus} />
        &nbsp;
        <FontAwesomeIcon icon={faUniversity} />
        &nbsp;Connect a Bank Account
      </Button>
      <Modal isOpen={isOpen} title='Choose Account To Link'>
        <Card>
          <CardBody>
            <Table>
              <thead>
                <tr>
                  <th className='fit' />
                  <th className='fit'>Account Name</th>
                  <th />
                </tr>
              </thead>
              <tbody>
                {plaidAccounts.map(
                  (account: IPlaidLinkerAccount, accountIndex: number) => {
                    return (
                      <tr key={accountIndex}>
                        <td className='fit'>
                          <Button
                            color='success'
                            onClick={() => {
                              handleAccountSelected(account)
                            }}
                          >
                            <FontAwesomeIcon icon={faExchangeAlt} />
                            &nbsp;Map This Account
                          </Button>
                        </td>
                        <td className='fit'>{account.name}</td>
                        <td />
                      </tr>
                    )
                  }
                )}
              </tbody>
            </Table>
          </CardBody>
        </Card>
      </Modal>
    </>
  )
}

export { PlaidLinker }
