import styled from 'styled-components'
// Routing
import { Link } from 'react-router-dom'
import { fonts } from 'config'
import { ISCNavLink } from './types'

export const SCNav = styled.div`
  display: flex;
  flex-direction: column;
  user-select: none;
  width: 100%;
  z-index: 9;
`

export const SCNavHeader = styled.div`
  display: flex;
  justify-content: center;
  padding: 35px 0 50px;
  text-align: center;
  width: 100%;
`

export const SCNavHeaderLogo = styled.img`
  display: block;
  height: 25px;
`

export const SCNavTop = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  width: 100%;
`

export const SCNavBottom = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
`
export const SCNavLink = styled(Link)<ISCNavLink>`
  display: flex;
  flex-directon: row;
  font-family: '${fonts.primary}', sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 16px;
  margin-bottom: 40px;
  padding-left: 50px;
  transition: color 0.2s ease-in-out;
  text-decoration: none;
  width: 100%;
  ${(props) =>
    props.active
      ? `
  color: #12c5b3;
  `
      : `
  color: #2a3c51;

  :hover {
    color: #7363d2;
  }
  `}
`

export const SCNavLinkIcon = styled.div`
  display: flex;
  justify-content: center;
  padding-right: 20px;
  width: 20px;
`

export const SCNavLinkText = styled.div`
  display: flex;
`

export const SCNavCard = styled.div`
  align-items: center;
  background: rgb(22, 181, 165);
  background: linear-gradient(
    90deg,
    rgba(22, 181, 165, 1) 0%,
    rgba(42, 157, 145, 1) 35%,
    rgba(18, 197, 179, 1) 100%
  );
  border: 1px solid #dee2e6;
  border-radius: 40px;
  box-shadow: 0 10px 30px 0 rgba(17, 38, 146, 0.05);
  color: #fafafa;
  display: flex;
  flex-direction: column;
  font-family: '${fonts.primary}', sans-serif;
  height: 200px;
  justify-content: center;
  margin: 0 20px 20px 20px;
`

export const SCNavCardLogo = styled.img`
  height: 25px;
  margin-bottom: 10px;
`
