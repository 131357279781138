// Styled Components
import styled from 'styled-components'
// Global App Config
import { fonts, screenSizes } from 'config'

export const SCModalOverlay = styled.div`
  background-color: rgba(0, 0, 0, 0.5);
  cursor: pointer;
  height: 100vh;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 20;
`

export const SCModal = styled.div`
  align-items: center;
  display: flex;
  height: 100vh;
  justify-content: center;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
`

export const SCModalBody = styled.div`
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 5px;
  box-shadow: 0 0 0 1px rgb(43, 45, 80, 0.1), 0 2px 5px 0 rgb(43, 45, 80, 0.08),
    0 1px 1.5px 0 rgb(0, 0, 0, 0.07), 0 1px 2px 0 rgb(0, 0, 0, 0.08);
  color: #000;
  cursor: default;
  display: flex;
  flex-direction: column;
  max-height: 80vh;
  min-width: 300px;
  overflow-y: auto;
  position: absolute;
  z-index: 999;

  ::-webkit-scrollbar {
    width: 14px;
  }

  ::-webkit-scrollbar-track {
    background: #ffffff;
    border-left: 1px solid #dee2e6;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #dee2e6;
    border-radius: 10px;
    border: 3px solid #ffffff;
  }

  @media (min-width: ${screenSizes.desktop}) {
    max-width: 750px;
    min-width: 500px;
  }
`

export const SCModalTitle = styled.div`
  border-bottom: 1px solid #edf2f9;
  color: #363940;
  font-family: '${fonts.primary}', sans-serif;
  font-size: 1.25rem;
  font-weight: 500;
  padding: 20px;
  width: 100%;
`

export const SCModalBodyContent = styled.div`
  padding: 20px;
`
