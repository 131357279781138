// Local Styled Components
import { SCFormGroupText } from './styles'
// Local TypeScript Types
import { IFormGroupText } from './types'

const FormGroupText: React.FC<IFormGroupText> = ({
  children
}: IFormGroupText) => {
  return <SCFormGroupText>{children}</SCFormGroupText>
}
export { FormGroupText }
