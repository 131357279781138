// Styled Components
import styled from 'styled-components'
// Global App Config
import { colors, fonts } from 'config'

export const SCInProgress = styled.div`
  background-color: ${colors.warning};
  border: 1px solid #dee2e6;
  border-radius: 15px;
  color: #3e3b1f;
  font-family: '${fonts.primary}', sans-serif;
  padding: 20px;
  user-select: none;
  width: 100%;

  strong {
    align-items: center;
    border-bottom: 1px solid #3e3b1f;
    display: flex;
    flex-direction: row;
    font-family: '${fonts.primary}', sans-serif;
    font-size: 1.25rem;
    font-weight: 600;
    margin-bottom: 20px;
    padding-bottom: 10px;
    width: 100%;

    img {
      padding-right: 10px;
    }
  }
`
