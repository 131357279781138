import { fonts } from 'config'

export const SCInputDefault = `
background-color: #fff;
border: 1px solid #cccccc;
border-radius: 0.25rem;
color: #495057;
display: block;
font-family: '${fonts.secondary}', sans-serif;
font-size: 15px;
font-weight: 500;
height: 50px;
line-height: 15px;
margin: 0;
padding: 10px;
width: 100%;

:focus-visible {
  outline: #ced4da auto 1px;
}

::after,
::before {
  box-sizing: border-box;
}
`
