// Styled Components
import styled from 'styled-components'
// Global App Config
import { colors, fonts } from 'config'
// TypeScript Types
import { ISCButton } from './types'

export const SCButton = styled.button<ISCButton>`
  background-color: white;
  border: 1px solid #cbd5e0;
  border-radius: 4px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  color: #2d3748;
  cursor: pointer;
  display: inline-block;
  font-family: '${fonts.primary}', sans-serif;
  font-size: 13px;
  font-weight: 400;
  line-height: 13px;
  outline: 0;
  padding: 8px;
  transition: all 200ms ease-in-out;
  user-select: none;

  ${(props) => props.block && 'width: 100%;'}
  ${(props) => {
    switch (props.color) {
      case 'success':
        return `
        background: rgb(22,181,165);
        color: #FFF;    

        .fa-spinner {
          color: #FFF;
        }

        :hover {
          background: rgb(42,157,145);
          color: #FAFAFA;

          .fa-spinner {
            color: #FAFAFA;
          }
        }
        `
      case 'error':
        return `
        background-color: ${colors.error};
        color: #b94238;
        `
      case 'warning':
        return `
        background-color: ${colors.warning};
        `
      case 'info':
        return `
          background-color: ${colors.primary};
          color: #FAFAFA;
          `
      case 'default':
      default:
        return `
        background-color: ${colors.secondary};
        border-color: #5656a3;
        color: #FFF;

        .fa-spinner {
          color: #FFF;
        }

        :hover {
          background-color: #9d9ed5;
          border-color: ${colors.secondary};
          color: #FAFAFA;

          .fa-spinner {
            color: #1d1e62;
          }
        }
        `
    }
  }}
  padding: 10px;

  :disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
`
