import { colors } from 'config'
import styled from 'styled-components'

export const SCTopNavBarContainer = styled.div`
  display: flex;
  flex: 1;
  height: 80px;
  padding-left: 255px;
  position: fixed;
  right: 15px;
  top: 0;
  width: 100%;
  z-index: 2;
`

export const SCTopNavBar = styled.div`
  background-color: #fff;
  border-top-left-radius: 35px;
  border: 1px solid #dee2e6;
  border-right: 0 none;
  box-shadow: 0 10px 30px 0 rgba(17, 38, 146, 0.05);
  align-items: center;
  display: flex;
  flex: 1;
  flex-direction: row;
  height: 100%;
  justify-content: center;
  left: 0;
  padding: 20px 0;
  user-select: none;
  width: 100%;
`

export const SCTopNavBarPad = styled.div`
  display: flex;
  height: 70px;
  min-height: 70px;
  position: relative;
  width: 100%;
`

export const SCTopNavBarWrapper = styled.div`
  overflow-x: auto;
  overflow-y: hidden;
  width: 100%;

  ::-webkit-scrollbar {
    height: 10px;
  }

  ::-webkit-scrollbar-track {
    background: #ffffff;
  }

  ::-webkit-scrollbar-thumb {
    background-color: ${colors.primary};
    border-radius: 6px;
    border: 3px solid #ffffff;
    opacity: 0.5;
  }
`
