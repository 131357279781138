import { Fragment } from 'react'
import { IPrettyOccurrence } from './types'

const PrettyOccurrence: React.FC<IPrettyOccurrence> = ({
  value,
  type,
  typeValue
}: IPrettyOccurrence) => {
  return (
    <Fragment>
      Every{value > 1 && ' ' + value.toString()}&nbsp;{type.toLowerCase()}
      {value > 1 && `${value.toString()} 's`} on
      {typeValue && type === 'Month'
        ? ' the ' + ordinalSuffixOf(parseInt(typeValue))
        : ` ${typeValue ?? ''}`}
    </Fragment>
  )
}

export default PrettyOccurrence

export function ordinalSuffixOf(i: number): string {
  const j = i % 10
  const k = i % 100
  if (j === 1 && k !== 11) {
    return i.toString() + 'st'
  }
  if (j === 2 && k !== 12) {
    return i.toString() + 'nd'
  }
  if (j === 3 && k !== 13) {
    return i.toString() + 'rd'
  }
  return i.toString() + 'th'
}
