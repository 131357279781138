import { Fragment } from 'react'
import { IPrettyDebtType } from './types'

const PrettyDebtType: React.FC<IPrettyDebtType> = ({
  debtType
}: IPrettyDebtType) => {
  return (
    <Fragment>
      {debtType === 'CreditCard'
        ? 'Credit Card'
        : debtType === 'StoreCard'
        ? 'Store Card'
        : debtType === 'AutoLoan'
        ? 'Auto Loan'
        : debtType === 'PersonalLoan'
        ? 'Personal Loan'
        : debtType === 'MedicalDebt'
        ? 'Medical Debt'
        : debtType}
    </Fragment>
  )
}

export default PrettyDebtType
