// Global - Components
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  CardHeaderTitle
} from 'components'
// Local - TypeScript Types
import { IPartnerCard } from './types'

const PartnerCard: React.FC<IPartnerCard> = ({
  description,
  logo,
  name,
  url
}: IPartnerCard) => {
  return (
    <>
      <Card>
        <CardHeader>
          <CardHeaderTitle>
            <a href={url}>
              <img alt={`${name} Logo`} src={logo} style={{ height: 25 }} />
            </a>
          </CardHeaderTitle>
        </CardHeader>
        <CardBody>
          <p>{description}</p>
        </CardBody>
        <CardFooter>
          <a href={url} rel='noopener noreferrer' target='blank'>
            <Button block>Learn More About {name}</Button>
          </a>
        </CardFooter>
      </Card>
    </>
  )
}

export { PartnerCard }
