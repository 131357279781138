import React from 'react'
import { APIAuthCurrentUserResponse } from 'api/auth/current-user/response'

interface IAppContext {
  loggedInUser: APIAuthCurrentUserResponse | undefined
  setLoggedInUser: React.Dispatch<
    React.SetStateAction<APIAuthCurrentUserResponse | undefined>
  >
}

const AppContext = React.createContext<IAppContext>({} as IAppContext)

export default AppContext
