// Local - Styled Components
import {
  SCPageTitle,
  SCPageTitleWrapper,
  SCPageTitleBackgroundImage,
  SCPageTitleActions,
  SCPageTitleTitles,
  SCPageTitleTitlesPrimary,
  SCPageTitleTitlesSecondary
} from './styles'
// Local - TypeScript Types
import { IPageTitle } from './types'

const PageTitle: React.FC<IPageTitle> = ({
  actions,
  subtitle,
  title
}: IPageTitle) => {
  return (
    <SCPageTitle>
      <SCPageTitleWrapper>
        <SCPageTitleTitles>
          <SCPageTitleTitlesPrimary>{title}</SCPageTitleTitlesPrimary>
          <SCPageTitleTitlesSecondary>{subtitle}</SCPageTitleTitlesSecondary>
        </SCPageTitleTitles>
        <SCPageTitleActions>{actions}</SCPageTitleActions>
      </SCPageTitleWrapper>
      <SCPageTitleBackgroundImage />
    </SCPageTitle>
  )
}

export { PageTitle }
