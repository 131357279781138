// Styled Components
import styled from 'styled-components'
// Global App Config
import { colors, fonts } from 'config'
// TypeScript Types
import { ISCBadge } from './types'

export const SCBadge = styled.span<ISCBadge>`
  align-items: center;
  background-color: rgb(248, 249, 250);
  border: 1px solid rgb(222, 226, 230);
  border-radius: 5px;
  color: #878787;
  display: inline-flex;
  font-family: '${fonts.primary}', sans-serif;
  font-size: 11px;
  font-weight: 400;
  padding: 5px;
  position: relative;
  ${(props) => {
    switch (props.color) {
      case 'success':
        return `
        background-color: ${colors.success};    
        border-color: #4a8756;
        color: #4a8756;
        `
      case 'error':
        return `
        background-color: ${colors.error};
        border-color: #ed969e;
        color: #b94238;
        `
      case 'warning':
        return `
        background-color: ${colors.warning};
        `
      case 'info':
        return `
          background-color: ${colors.primary};
          `
      case 'default':
      default:
        return `
        background-color: rgb(248, 249, 250);
        `
    }
  }}
  width: auto;
`
