// Local Styled Components
import {
  SCPrettyInstitutionImage,
  SCPrettyInstitutionLink,
  SCPrettyInstitutionWrapper
} from './styles'
// Local TypeScript Interfaces
import { IPrettyInstitution } from './types'
// Local Data
import { Institutions } from 'data/institutions'
// Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGlobe } from '@fortawesome/free-solid-svg-icons'

const PrettyInstitution: React.FC<IPrettyInstitution> = ({
  institution,
  url
}: IPrettyInstitution) => {
  // Try to find the requested institution
  const institutionIndex = Institutions.findIndex((institutionInList) => {
    return institutionInList.name.toLowerCase() === institution.toLowerCase()
  })
  // If an institution was found
  if (institutionIndex > -1) {
    const institutionFound = Institutions[institutionIndex]
    return (
      <>
        <SCPrettyInstitutionWrapper>
          <SCPrettyInstitutionImage
            alt={`${institutionFound.name} Logo`}
            src={institutionFound.logo}
          />
        </SCPrettyInstitutionWrapper>
        {url && (
          <SCPrettyInstitutionLink href={url} tabIndex={-1} target='blank'>
            <FontAwesomeIcon icon={faGlobe} />
            &nbsp;View Site
          </SCPrettyInstitutionLink>
        )}
      </>
    )
  }
  return null
}

export default PrettyInstitution
